// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__topbar___EuJRa {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  top: 1.25rem;
  left: 1.25rem;
  right: 1.25rem;
  z-index: 20;
}
@media (min-width: 1440px) {
  .styles__topbar___EuJRa {
    top: 1.38vw;
    left: 1.38vw;
    right: 1.38vw;
  }
}
.styles__topbar-logo___EjEgz {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.styles__topbar-logo___EjEgz svg {
  width: 135px;
  height: 32px;
}
@media (min-width: 1440px) {
  .styles__topbar-logo___EjEgz svg {
    width: 7.375vw;
    height: 2.22vw;
  }
}
.styles__topbar-tag___eWN47 {
  font-size: 14px;
}
@media (min-width: 1440px) {
  .styles__topbar-tag___eWN47 {
    font-size: 0.97vw;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/TopBar/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,8BAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;EAEA,WAAA;AAAF;AAEE;EAXF;IAYI,WAAA;IACA,YAAA;IACA,aAAA;EACF;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;AACJ;AACI;EACE,YAAA;EACA,YAAA;AACN;AAEI;EACE;IACE,cAAA;IACA,cAAA;EAAN;AACF;AAIE;EACE,eAAA;AAFJ;AAII;EAHF;IAII,iBAAA;EADJ;AACF","sourcesContent":[".topbar {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  position: fixed;\n  top: 1.25rem;\n  left: 1.25rem;\n  right: 1.25rem;\n\n  z-index: 20;\n\n  @media (min-width: 1440px) {\n    top: 1.38vw;\n    left: 1.38vw;\n    right: 1.38vw;\n  }\n\n  &-logo {\n    display: flex;\n    align-items: flex-start;\n    flex-direction: column;\n\n    & svg {\n      width: 135px;\n      height: 32px;\n    }\n\n    @media (min-width: 1440px) {\n      & svg {\n        width: 7.375vw;\n        height: 2.22vw;\n      }\n    }\n  }\n\n  &-tag {\n    font-size: 14px;\n    \n    @media (min-width: 1440px) {\n      font-size: 0.97vw;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topbar": `styles__topbar___EuJRa`,
	"topbar-logo": `styles__topbar-logo___EjEgz`,
	"topbar-tag": `styles__topbar-tag___eWN47`
};
export default ___CSS_LOADER_EXPORT___;
