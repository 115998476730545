// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__decor-container___tT_Vn {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  height: 100px;
  border-bottom: 1px solid #000;
}
@media (min-width: 768px) {
  .styles__decor-container___tT_Vn {
    height: 138px;
  }
}
@media (min-width: 1280px) {
  .styles__decor-container___tT_Vn {
    height: 9.58vw;
  }
}

.styles__ticker-animation___uRAKt {
  position: absolute;
  height: 55px;
}
@media (min-width: 1280px) {
  .styles__ticker-animation___uRAKt {
    height: 3.819vw;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/Footer/Decor/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,qBAAA;EACA,gBAAA;EACA,aAAA;EAUA,6BAAA;AARF;AAAE;EAPF;IAQI,aAAA;EAGF;AACF;AADE;EAXF;IAYI,cAAA;EAIF;AACF;;AACA;EACE,kBAAA;EACA,YAAA;AAEF;AAAE;EAJF;IAKI,eAAA;EAGF;AACF","sourcesContent":[".decor-container {\n  position: relative;\n  display: flex;\n  align-items: flex-end;\n  overflow: hidden;\n  height: 100px;\n\n  @media (min-width: 768px) {\n    height: 138px;\n  }\n\n  @media (min-width: 1280px) {\n    height: 9.58vw;\n  }\n\n  border-bottom: 1px solid #000;\n}\n\n.ticker-animation {\n  position: absolute;\n  height: 55px;\n\n  @media (min-width: 1280px) {\n    height: 3.819vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"decor-container": `styles__decor-container___tT_Vn`,
	"ticker-animation": `styles__ticker-animation___uRAKt`
};
export default ___CSS_LOADER_EXPORT___;
