// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__info-button___xfk1k {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.styles__info-button___xfk1k svg {
  width: 1.6vw;
  height: 1.6vw;
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/TopBar/InfoAbout/styles.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,6BAAA;EACA,YAAA;EAEA,eAAA;AAFJ;AAKI;EACE,YAAA;EACA,aAAA;AAHN","sourcesContent":[".info {\n  &-button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    background-color: transparent;\n    border: none;\n\n    cursor: pointer;\n    \n\n    & svg {\n      width: 1.6vw;\n      height: 1.6vw;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-button": `styles__info-button___xfk1k`
};
export default ___CSS_LOADER_EXPORT___;
