// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__subtitle___y1AWV {
  color: #FFF;
  font-family: "Neue Montreal", sans-serif;
  font-weight: 300;
}

@media (min-width: 640px) {
  .styles__subtitle___y1AWV {
    font-size: 2.2vw;
  }
}
@media (min-width: 1024px) {
  .styles__subtitle___y1AWV {
    font-size: 1.2vw;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/IntroductorySection/SubTitle/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,wCAAA;EACA,gBAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;AACF;AAEA;EACE;IACE,gBAAA;EAAF;AACF","sourcesContent":[".subtitle {\n  color: #FFF;\n  font-family: 'Neue Montreal', sans-serif;\n  font-weight: 300;\n}\n\n@media (min-width: 640px) {\n  .subtitle {\n    font-size: 2.2vw;\n  }\n}\n\n@media (min-width: 1024px) {\n  .subtitle {\n    font-size: 1.2vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": `styles__subtitle___y1AWV`
};
export default ___CSS_LOADER_EXPORT___;
