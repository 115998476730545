// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__main-button___bO3Hc {
  padding: 22px 40px;
  box-sizing: border-box;
  color: #000;
  background-color: #FF7538;
  border: none;
  font-size: 18px;
  line-height: normal;
  font-weight: normal;
  cursor: pointer;
  border-radius: 8px;
}
.styles__main-button___bO3Hc:hover {
  color: #000;
  background-color: #ff611e;
}
@media (min-width: 1440px) {
  .styles__main-button___bO3Hc {
    padding: 1vw 1.8vw;
    font-size: 1vw;
    border-radius: 0.5vw;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/IntroductorySection/MainButton/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,sBAAA;EAEA,WAAA;EACA,yBAAA;EACA,YAAA;EAEA,eAAA;EACA,mBAAA;EACA,mBAAA;EAEA,eAAA;EACA,kBAAA;AAFF;AAIE;EACE,WAAA;EACA,yBAAA;AAFJ;AAKE;EApBF;IAqBI,kBAAA;IACA,cAAA;IACA,oBAAA;EAFF;AACF","sourcesContent":[".main-button {\n  padding: 22px 40px;\n  box-sizing: border-box;\n\n  color: #000;\n  background-color: #FF7538;\n  border: none;\n\n  font-size: 18px;\n  line-height: normal;\n  font-weight: normal;\n\n  cursor: pointer;\n  border-radius: 8px;\n\n  &:hover {\n    color: #000;\n    background-color: #ff611e;\n  }\n\n  @media (min-width: 1440px) {\n    padding: 1vw 1.8vw;\n    font-size: 1vw;\n    border-radius: 0.5vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-button": `styles__main-button___bO3Hc`
};
export default ___CSS_LOADER_EXPORT___;
