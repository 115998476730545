// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__copyright___qvFPl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;
}
.styles__copyright-text___sFkzU {
  font-size: 1.125rem;
}
@media (min-width: 1280px) {
  .styles__copyright-text___sFkzU {
    font-size: 1.25vw;
  }
}

.styles__back-to-top-button___GHMAN {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  cursor: pointer;
  font-size: 1.125rem;
  background-color: transparent;
  border: none;
}
@media (min-width: 1280px) {
  .styles__back-to-top-button___GHMAN {
    font-size: 1.25vw;
  }
}
.styles__back-to-top-button___GHMAN:hover {
  color: #FF6F3E;
}
.styles__back-to-top-arrow___ovVHn {
  display: flex;
  align-items: center;
  width: 12px;
  height: 15px;
}
.styles__back-to-top-arrow___ovVHn svg {
  width: 100%;
  height: 100%;
}
@media (min-width: 1280px) {
  .styles__back-to-top-arrow___ovVHn {
    width: 0.76vw;
    height: 0.9vw;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/Footer/BackCopyright/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;AACF;AACE;EACE,mBAAA;AACJ;AACI;EAHF;IAII,iBAAA;EAEJ;AACF;;AAGE;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;EACA,6BAAA;EACA,YAAA;AAAJ;AAEI;EATF;IAUI,iBAAA;EACJ;AACF;AACI;EACE,cAAA;AACN;AAGE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AADJ;AAGI;EACE,WAAA;EACA,YAAA;AADN;AAII;EAXF;IAYI,aAAA;IACA,aAAA;EADJ;AACF","sourcesContent":[".copyright {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 4rem;\n\n  &-text {\n    font-size: 1.125rem;\n    \n    @media (min-width: 1280px) {\n      font-size: 1.25vw;\n    }\n  }\n}\n\n.back-to-top {\n  &-button {\n    display: flex;\n    align-items: center;\n    gap: 0.375rem;\n    cursor: pointer;\n    font-size: 1.125rem;\n    background-color: transparent;\n    border: none;\n    \n    @media (min-width: 1280px) {\n      font-size: 1.25vw;\n    }\n\n    &:hover {\n      color: #FF6F3E;\n    }\n  }\n  \n  &-arrow {\n    display: flex;\n    align-items: center;\n    width: 12px;\n    height: 15px;\n\n    & svg {\n      width: 100%;\n      height: 100%;\n    }\n\n    @media (min-width: 1280px) {\n      width: 0.76vw;\n      height: 0.9vw;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyright": `styles__copyright___qvFPl`,
	"copyright-text": `styles__copyright-text___sFkzU`,
	"back-to-top-button": `styles__back-to-top-button___GHMAN`,
	"back-to-top-arrow": `styles__back-to-top-arrow___ovVHn`
};
export default ___CSS_LOADER_EXPORT___;
