// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__section___qq4lL {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
  .styles__section___qq4lL {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
@media (min-width: 1024px) {
  .styles__section___qq4lL {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}
@media (min-width: 1280px) {
  .styles__section___qq4lL {
    padding-top: 2.77vw;
    padding-bottom: 2.77vw;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/Footer/Section/styles.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,oBAAA;EACA,cAAA;EACA,WAAA;AACF;AACE;EANF;IAOI,iBAAA;IACA,oBAAA;EAEF;AACF;AAAE;EAXF;IAYI,mBAAA;IACA,sBAAA;EAGF;AACF;AADE;EAhBF;IAiBI,mBAAA;IACA,sBAAA;EAIF;AACF","sourcesContent":[".section {\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n  margin: 0 auto;\n  width: 100%;\n\n  @media (min-width: 768px) {\n    padding-top: 2rem;\n    padding-bottom: 2rem;\n  }\n\n  @media (min-width: 1024px) {\n    padding-top: 2.5rem;\n    padding-bottom: 2.5rem;\n  }\n\n  @media (min-width: 1280px) {\n    padding-top: 2.77vw;\n    padding-bottom: 2.77vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `styles__section___qq4lL`
};
export default ___CSS_LOADER_EXPORT___;
