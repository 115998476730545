import * as React from "react";
import { Link } from "react-router-dom";
import { Section, Hero, Container, Heading } from "react-bulma-components";

export default function Terms() {
  return (
    <div className="landing-page info has-background-black has-text-silver pb-6">
      <Hero size="medium" gradient={true}>
        <Hero.Body className="has-text-centered">
          <Container>
            <Heading>Terms of Service</Heading>
            <p>Last Updated: February 8, 2022</p>
          </Container>
        </Hero.Body>
      </Hero>

      <Section>
        <Container>
          <p className="mb-4">
            This page explains the terms by which you may use the websites and
            Company Content (as defined below) of Modclub Foundation (
            <span className="has-text-white has-text-weight-bold">“we”</span>,{" "}
            <span className="has-text-white has-text-weight-bold">“our”</span>{" "}
            and{" "}
            <span className="has-text-white has-text-weight-bold">“us”</span>),
            including without limitation modclub.app (collectively, the{" "}
            <span className="has-text-white has-text-weight-bold">“Sites”</span>
            ) and our Services (as defined below). By accessing or using the
            Sites or the Services, you signify on your behalf and any person or
            entity that you represent that you have read, understood, and agree
            to be bound by these Terms of Service (the{" "}
            <span className="has-text-white has-text-weight-bold">
              “Agreement”
            </span>
            ), whether or not you are a registered user of our Sites or the
            Services. We reserve the right to make unilateral modifications to
            these terms and will provide notice of these changes as described
            below. This Agreement applies to all visitors, users, and others who
            access the Sites or the Services (
            <span className="has-text-white has-text-weight-bold">“you”</span>{" "}
            and{" "}
            <span className="has-text-white has-text-weight-bold">“your”</span>
            ).
          </p>
          <p>
            Please read this Agreement carefully to ensure that you understand
            each provision.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading className="has-text-centered" mb={6}>
            NONE OF THE SITES OR THE SERVICES ARE OFFERED TO PERSONS OR ENTITIES
            WHO ARE RESTRICTED PERSONS (AS DEFINED BELOW).
          </Heading>
          <Heading subtitle>Our Sites and Services.</Heading>
          <p>
            As part of the Sites, we provide access to, among other services,
            the MODCLUB platform (the{" "}
            <span className="has-text-white has-text-weight-bold">
              “Platform”
            </span>
            ). This Agreement governs your use of our Sites, the Platform, and
            all related tools, applications, data, software and other services
            provided by us (collectively, the{" "}
            <span className="has-text-white has-text-weight-bold">
              “Services”
            </span>
            ).
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading className="has-text-centered">
            Use of Our Sites and Services.
          </Heading>
          <Heading subtitle>Eligibility</Heading>
          <p className="mb-4">
            This is a contract between you and us. You must read and agree to
            the terms of this Agreement before using the Sites and the Services.
            If you do not agree, you may not use the Sites and the Services. You
            may use the Sites and the Services only if you can form a binding
            contract with us, and only in compliance with this Agreement and all
            applicable: (i) laws, constitutions, treaties, statutes, codes,
            ordinances, principles of common and civil law and equity, orders,
            decrees, rules, regulations and municipal by-laws, whether domestic,
            foreign or international; (ii) judicial, arbitral, administrative,
            ministerial, departmental and regulatory judgments, orders, writs,
            injunctions, decisions, rulings, decrees and awards of any: (A)
            multinational or supranational body or organization, nation,
            government, state, province, country, territory, municipality,
            quasi-government, administrative, judicial or regulatory authority,
            agency, board, body, bureau, commission, instrumentality, court or
            tribunal or any political subdivision thereof, or any central bank
            (or similar monetary or regulatory authority) thereof, any taxing
            authority, any ministry or department or agency of any of the
            foregoing; (B) self-regulatory organization or stock exchange; (C)
            entity exercising executive, legislative, judicial, regulatory or
            administrative functions of or pertaining to government; and (D)
            corporation or other entity owned or controlled, through stock or
            capital ownership or otherwise, by any of such entities or other
            bodies pursuant to the foregoing (
            <span className="has-text-white has-text-weight-bold">
              “Governmental Authority”
            </span>
            ); and (iii) policies, practices and guidelines of, or contracts
            with, any Governmental Authority, which, although not actually
            having the force of law, are considered by such Governmental
            Authority as requiring compliance as if having the force of law, as
            the same may be amended from time to time and any successor thereto
            and in each case binding on or affecting the person or entity, or
            the assets of the person or entity, referred to in the context in
            which such word is used; (collectively,{" "}
            <span className="has-text-white has-text-weight-bold">
              “Applicable Laws”
            </span>
            ). If you are under the age of majority in your jurisdiction of
            residence, you may use the Sites or the Services only with the
            consent of or under the supervision of your parent or legal
            guardian. The Sites and the Services are not available to any person
            previously removed from the Sites or the Services by us.
          </p>
          <p>
            By entering or using this Sites or the Services you represent that
            you:
          </p>
          <ul style={{ listStyle: "disc" }}>
            <li>
              have the right, authority and capacity to enter into this
              Agreement on behalf of yourself and the person or entity that you
              represent (if applicable);
            </li>
            <li>
              are not prohibited from entering or using this Sites or the
              Services or transacting with the Platform under any Applicable
              Laws;
            </li>
            <li>
              understand the risks associated with using our Sites and the
              Services, including using the Platform; and
            </li>
            <li>
              understand that you will be dealing with third parties and we have
              not verified and will not be in a position to verify any
              transaction you make using the Platform.
            </li>
          </ul>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>User Responsibilities</Heading>
          <p>
            In order to use certain features of the Sites and Services, you may
            be required to connect to your digital asset wallet.  You
            acknowledge that we are not responsible for transferring,
            safeguarding, or maintaining your private keys or any assets
            associated therewith.  If you lose, mishandle or have stolen your
            digital asset wallet private keys, you acknowledge that you may not
            be able to recover associated assets and that we are not responsible
            for such loss. You agree that you will not use the Services to
            transact with any digital currency that may be considered a security
            under Applicable Laws. You acknowledge that we are not responsible
            for, and you agree to indemnify us for, any loss or damage arising
            from your failure to comply with the requirements hereunder.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Access and Use of the Sites and Services</Heading>
          <p className="mb-4">
            Subject to the terms and conditions of this Agreement, you are
            hereby granted a non-exclusive, limited, non-transferable,
            non-sublicensable and freely revocable right to use the Sites and
            the Services. We reserve all rights not expressly granted herein in
            the Sites, the Services and the Company Content (as defined below).
            We may terminate this right of use, in whole or in part, at any time
            for any reason or no reason.
          </p>
          <p className="mb-4">
            The rights granted to you in this Agreement are subject to the
            following restrictions: (i) you shall not license, sell, rent,
            lease, transfer, assign, distribute, host, or otherwise commercially
            exploit the Sites or the Services, whether in whole or in part, or
            any content displayed on the Sites or the Services; (ii) you shall
            not modify, make derivative works of, disassemble, reverse compile
            or reverse engineer any part of the Sites or the Services; (iii) you
            shall not access the Sites or the Services in order to build a
            similar or competitive websites, product, or service; (iv) except as
            expressly stated herein, no part of the Sites or the Services may be
            copied, reproduced, distributed, republished, downloaded, displayed,
            posted or transmitted in any form or by any means; and (v) you shall
            not use the Sites or the Services other than as permitted hereunder.
             Unless otherwise indicated, any future release, update, or other
            addition to the functionality of the Sites and Services shall be
            subject to this Agreement. All copyright and other proprietary
            notices on the Sites (or on any content displayed on the Sites) must
            be retained on all copies thereof.
          </p>
          <p>
            You acknowledge and agree that we have no obligation to provide you
            with any support or maintenance in connection with the Sites and the
            Services.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Acceptable Use</Heading>
          <p className="mb-4">
            You agree not to engage in any of the following prohibited
            activities: (i) copying, distributing, or disclosing any part of the
            Sites or the Services in any medium, including without limitation by
            any automated or non-automated “scraping”; (ii) using any automated
            system, including without limitation “robots”, “spiders” and
            “offline readers” to access the Sites or the Services in a manner
            that sends more request messages to our servers than a human can
            reasonably produce in the same period of time by using a
            conventional on-line web browser (except that we grant the operators
            of public search engines revocable permission to use spiders to copy
            publicly available materials from the Sites for the sole purpose of
            and solely to the extent necessary for creating publicly available
            searchable indices of the materials, but not caches or archives of
            such materials); (iii) transmitting spam, chain letters, or other
            unsolicited email or messages through the Sites or the Services;
            (iv) attempting to interfere with, compromise the system integrity
            or security of or decipher any transmissions to or from the servers
            running the Sites or the Services; (v) taking any action that
            imposes, or may impose at our sole discretion an unreasonable or
            disproportionately large load on our infrastructure; (vi) uploading
            invalid data, viruses, worms, or other software agents through the
            Sites or the Services; (vii) collecting or harvesting any personally
            identifiable information, including account names, from the Sites or
            the Services; (viii) using the Sites or the Services for any
            commercial solicitation purposes; (ix) impersonating another person
            or otherwise misrepresenting your affiliation with a person or
            entity, conducting fraud, hiding or attempting to hide your
            identity; (x) interfering with the proper working of the Sites or
            the Services; (xi) accessing any content on the Sites or the
            Services through any technology or means other than those provided
            or authorized by the Sites or the Services; (xii) bypassing the
            measures we may use to prevent or restrict access to the Sites or
            the Services, including without limitation features that prevent or
            restrict use or copying of any content or enforce limitations on use
            of the Sites or the content therein; (xiii) using the Sites or the
            Services for any purpose prohibited or regulated by Applicable Laws;
            (xiv) using the Sites or the Services to collect, upload, transmit,
            display, or distribute any content: (A) that violates any
            third-party right, including any copyright, trademark, patent, trade
            secret, moral right, privacy right, right of publicity, or any other
            intellectual property or proprietary right; (B) that is unlawful,
            harassing, abusive, tortious, threatening, harmful, invasive of
            another’s privacy, vulgar, defamatory, false, intentionally
            misleading, trade libelous, pornographic, obscene, patently
            offensive, promotes racism, bigotry, hatred, or physical harm of any
            kind against any group or individual or is otherwise objectionable;
            or (C) that is harmful to minors in any way; or (xv) using the Sites
            for any purposes other than using our Services.
          </p>
          <p className="mb-4">
            We may, without prior notice, change the Sites or the Services; stop
            providing the Sites or the Services or features of the Sites or the
            Services to you or to users generally; or create usage limits for
            the Sites or the Services. We may permanently or temporarily
            terminate or suspend your access to the Sites or the Services
            without notice and liability for any reason, including if in our
            sole determination you violate any provision of this Agreement, or
            for no reason. Upon termination for any reason or no reason, you
            continue to be bound by this Agreement. You agree that we will not
            be liable to you or to any third party for any modification,
            suspension, or discontinuation of the Sites or the Services or any
            part thereof.
          </p>
          <p className="mb-4">
            You are solely responsible for your interactions with other users of
            the Sites and the Services. We reserve the right, but have no
            obligation, to monitor disputes between you and other users. We
            shall have no liability for your interactions with other users, or
            for any user's action or inaction.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Sites Location; Restrictions on Use</Heading>
          <p>
            The Sites are controlled and operated from facilities in Panama. We
            make no representations that the Sites or the Services are
            appropriate or available for use in other locations. Those who
            access or use the Sites or the Services from other jurisdictions do
            so at their own volition and are entirely responsible for compliance
            with all Applicable Laws, including but not limited to export and
            import regulations.{" "}
            <span className="has-text-white has-text-weight-bold">
              You may not use the Sites or the Services if you are a person or
              entity resident in, a citizen of, located in, incorporated, formed
              or organized in or have a registered office in (i) a country
              embargoed by the government of Canada, the United States, the
              United Kingdom or the European Union or are a foreign person or
              entity blocked or denied by the Canadian, United States, the
              United Kingdom or any European Union government or (ii) a country
              which has been sanctioned by the United Nations (any such person
              or entity being a “Restricted Person”).
            </span>{" "}
            Unless otherwise explicitly stated, all materials found on the Sites
            and the Services are solely directed to individuals, companies, or
            other entities located in Panama.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Our Proprietary Rights.</Heading>
          <p className="mb-4">
            The Sites and the Services and all materials therein or transferred
            thereby, including, without limitation, software, images, text,
            graphics, illustrations, logos, patents, trademarks, service marks,
            copyrights, photographs, audio, videos and music and all
            intellectual property rights related thereto (the{" "}
            <span className="has-text-white has-text-weight-bold">
              “Company Content”
            </span>
            ) but excluding all material, content or information posted or
            upload by you or other users to the Sites or the Services (
            <span className="has-text-white has-text-weight-bold">
              “User Content”
            </span>
            ), are the exclusive property of us and our licensors. Except as
            explicitly provided herein, nothing in this Agreement shall be
            deemed to create a license in or under any such Company Content or
            intellectual property rights, and you agree not to sell, license,
            rent, modify, distribute, copy, reproduce, transmit, publicly
            display, publicly perform, publish, adapt, edit or create derivative
            works from any Company Content. Use of the Company Content for any
            purpose not expressly permitted by this Agreement is strictly
            prohibited. Company Content is made available solely for your
            personal, non-commercial use and may not be copied, reproduced,
            published, republished, modified, mirrored, uploaded, posted,
            transmitted, displayed, encoded, translated or distributed in any
            form or in way, including by e-mail or other electronic means, or
            stored in any retrieval system of any nature in any way, without the
            express prior written consent of us or such third party that may own
            such Company Content in each instance. You agree to abide by all
            copyright and other proprietary notices, information and
            restrictions contained in the Company Content and any other material
            accessed through the Sites.
          </p>
          <p>
            You may choose to, or we may invite you to submit comments,
            feedback, or ideas about the Sites and the Services, including
            without limitation about how to improve the Sites or our Services (
            <span className="has-text-white has-text-weight-bold">
              “Feedback”
            </span>
            ). By submitting any Feedback, you agree that (a) your disclosure is
            non-confidential, gratuitous, unsolicited and without restriction
            and will not place us under any fiduciary or other obligation, (b)
            you grant to us a perpetual, worldwide, royalty-free, irrevocable,
            transferable, sublicensable, non-exclusive and fully paid-up right
            to copy, use, reproduce, modify, adapt, publish, create derivative
            works from, translate, transmit, display, distribute, market,
            promote, sell or offer for sale, rent or lease such information or
            materials or any portions thereof (including any ideas for new
            products or Services or modifications to existing products or
            Services) and/or products or Services which practice or embody, or
            are configured for use in practicing, such information or materials
            or any portion thereof, in any form or medium known or later
            developed, in furtherance of the terms of the Agreement and the
            actions and transactions contemplated hereby, including the right to
            bring an action for infringement of these rights, (c)  we are free
            to use the Feedback without any additional compensation to you,
            and/or to disclose the Feedback on a non-confidential basis or
            otherwise to anyone and (d) you will have no claim against for any
            actual or alleged infringement of any proprietary rights, rights of
            privacy or publicity, moral rights or rights of attribution in
            connection with our use of any Feedback you provide. You further
            acknowledge that, by acceptance of your submission, we do not waive
            any rights to use similar or related comments, feedback and ideas
            previously known to us, or developed by our employees, or obtained
            from sources other than you.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>No Professional Advice</Heading>
          <p>
            All information on the Sites and the Services is for informational
            purposes only and should not be construed as professional advice.
            You should contact your own legal, financial, tax or other
            professional advisors. Neither the Sites, the Services nor we
            provide any legal, financial, taxation or any other professional
            advice. No action should be taken based upon any information
            contained in the Sites or the Services. You should seek independent
            professional advice from a person who is licensed and/or qualified
            in the applicable area.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Privacy</Heading>
          <p>
            We care about the privacy of our users. You understand that by using
            the Sites you consent to the collection, use and disclosure of
            personally identifiable information and aggregate data, and to have
            your personally identifiable information collected, used,
            transferred to and processed in accordance with our Privacy Policy,
            a copy of which can be found <Link to="/privacy">here</Link>. Our
            Privacy Policy is hereby incorporated into and forms part of this
            Agreement.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>
            Cautionary Note on Forward-looking Statements.
          </Heading>
          <p>
            All statements contained in the Company Content, the Services, the
            Sites, statements made in press releases or in any place accessible
            by the public and oral statements that may be made Company Parties
            may constitute forward-looking statements (including statements
            regarding the intent, belief or current expectations with respect to
            market conditions, business strategy and plans, financial condition,
            specific provisions and risk management practices). You are
            cautioned not to place undue reliance on these forward-looking
            statements given that these statements involve known and unknown
            risks, uncertainties and other factors that may cause the actual
            future results to be materially different from that described by
            such forward-looking statements, and no independent third party has
            reviewed the reasonableness of any such statements or assumptions.
            These forward-looking statements are applicable only as of the date
            indicated therein and the Company Parties disclaim any
            responsibility (whether express or implied) to release any revisions
            to these forward-looking statements to reflect events after such
            date.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Cryptographic Systems</Heading>
          <p>
            You acknowledge and understand that cryptography is a progressing
            field. Advances in code cracking or technical advances such as the
            development of quantum computers may present risks to cryptographic
            systems and the Services, which could result in the theft or loss of
            your assets. To the extent possible, we intend to update or propose
            updates to the code underlying the Services to account for any
            advances in cryptography and to incorporate additional security
            measures but does not guarantee or otherwise represent full security
            of the system. By using the Services, you acknowledge these inherent
            risks.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Security</Heading>
          <p className="mb-4">
            We are an early stage platform. You acknowledge that applications
            are code that are subject to flaws and acknowledge that you are
            solely responsible for evaluating any available code provided by the
            Services. You further expressly acknowledge and represent that
            applications can be written maliciously or negligently, and that we
            cannot be held liable for your interaction with such applications.
            These warnings and others later provided by us in no way evidence or
            represent an ongoing duty to alert you to all the potential risks of
            utilizing the Sites and Services.
          </p>
          <p>
            We use commercially reasonable physical, managerial, and technical
            safeguards to preserve the integrity and security of your personal
            information and implement your privacy settings. However, we cannot
            guarantee that unauthorized third parties will never be able to
            defeat our security measures or use your personal information for
            improper purposes. You acknowledge that you provide your personal
            information at your own risk.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Third-Party Links and Information</Heading>
          <p>
            The Sites or the Services may contain links to third-party materials
            that are not owned or controlled by us. we do not endorse or assume
            any responsibility for any such third-party websites, information,
            materials, products, or services. If you access a third-party
            website or service from the Sites or the Services on or through any
            third-party website or service, you do so at your own risk, and you
            understand that this Agreement and our Privacy Policy do not apply
            to your use of such websites. You expressly relieve us from any and
            all liability arising from your use of any third-party website,
            service, or content. Additionally, your dealings with or
            participation in promotions of advertisers found on the Sites or the
            Services, including payment and delivery of goods, and any other
            terms (such as warranties) are solely between you and such
            advertisers. You agree that we shall not be responsible for any loss
            or damage of any sort relating to your dealings with such
            advertisers.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Indemnity</Heading>
          <p>
            You agree to defend, indemnify and hold harmless us and our
            suppliers, partners, licensors, dealers, representatives, associates
            or affiliates, and each of their respective employees, contractors,
            agents, representatives, shareholders, officers and directors
            (collectively, the{" "}
            <span className="has-text-white has-text-weight-bold">
              “Company Parties”
            </span>
            ), from and against any and all claims, damages, obligations,
            losses, liabilities, costs or debt, and expenses (including but not
            limited to lawyer’s fees and disbursements) arising from: (a) your
            use of and access to the Sites, Company Content and the Services,
            including any act or omission by you or users of your account or any
            data or content transmitted or received by you; (b) your violation
            of any term of this Agreement, including without limitation your
            breach of any of the representations and warranties above; (c) your
            violation of any third-party right, including without limitation any
            right of privacy or intellectual property rights; (d) your violation
            of any Applicable Laws; (e) any User Content that you submit to the
            Sites or the Services including without limitation misleading,
            false, or inaccurate information; (f) your willful misconduct; or
            (g) any other party’s access and use of the Sites, Company Content
            or the Services with your unique username, password or other
            appropriate security code. You will co-operate as fully as
            reasonably required in the defense of any claim.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>No Warranty</Heading>
          <p className="mb-4">
            The Sites and the Services are provided on an “as is” and “as
            available” basis. Use of the Sites, Company Content and the Services
            is at your own risk. To the maximum extent permitted by applicable
            law, the Sites, Company Content and the Services are provided
            without warranties of any kind, whether express or implied,
            including, but not limited to, implied warranties of
            merchantability, fitness for a particular purpose, or
            non-infringement. No advice or information, whether oral or written,
            obtained by you from a Company Party or through the Sites or the
            Services will create any warranty not expressly stated herein.
            Without limiting the foregoing, the Company Parties do not warrant
            that the Company Content is accurate, reliable or correct; that the
            Sites, Company Content and the Services will meet your requirements;
            that the Sites, Company Content and the Services will be available
            at any particular time or location, uninterrupted or secure; that
            any defects or errors will be corrected; or that the Sites, Company
            Content and the Services are free of viruses or other harmful
            components. Any content downloaded or otherwise obtained through the
            use of the Sites or the Services is downloaded at your own risk and
            you will be solely responsible for any damage to your computer
            system or mobile device or loss of data that results from such
            download or your use of the Sites, Company Content or the Services.
          </p>
          <p>
            We do not warrant, endorse, guarantee, or assume responsibility for
            any product or websites advertised or offered by a third party
            through the Sites or the Services or any hyperlinked websites, and
            we will not be a party to or in any way monitor any transaction
            between you and third-party providers of products or services.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Disclaimers</Heading>
          <p>
            None of the Company Parties will have any responsibilities or
            liability with respect to the following: (a) the Services could be
            impacted by one or more regulatory inquiries or actions, which could
            prevent or limit our ability to continue to develop or provide the
            Services, or for you and your users to use the Services, (b) we have
            no obligation to update the Services or its underlying platforms and
            networks to address, mitigate, or remediate any security or other
            vulnerabilities in the Services, or such platforms or networks and
            (c) portions of the Services or any other underlying networks and
            platforms may rest on open-source software, and there is a risk that
            weaknesses or bugs that may be introduced in the infrastructural
            elements of the Services or any other underlying networks and
            platforms, which may result in security vulnerabilities, data loss,
            damage, destructions, disclosure, or other compromises.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>
            Assumption of Risk; Limitation of Liability
          </Heading>
          <p className="mb-4">
            You acknowledge that the Sites and the Services, and your use of the
            Sites and the Services contain certain risks, including without
            limitation the following risks:{" "}
            <span className="has-text-white has-text-weight-bold">
              That any Services you interact with are entirely your own
              responsibility and liability, and we are not a party to the
              Platform; at any time, your access to your digital assets may be
              suspended or terminated or there may be a delay in your access or
              use of your digital assets which may result in the digital assets
              diminishing in value; and the Services may be suspended or
              terminated for any or no reason, which may limit your access to
              your digital assets.
            </span>{" "}
            Accordingly, you expressly agree that you assume all risk in
            connection with your access and use of the Sites and Services.
          </p>
          <p className="mb-4">
            You also agree that to the maximum extent permitted by Applicable
            Laws, in no event shall any Company Party be liable for any
            indirect, punitive, incidental, special, consequential or exemplary
            damages, including without limitation damages for loss of profits,
            goodwill, use, data or other intangible losses, arising out of or
            relating to the use of, or inability to use, the Sites, Company
            Content or the Services. Under no circumstances will any Company
            Party be responsible for any damage, loss or injury resulting from
            hacking, tampering or other unauthorized access or use of the Sites,
            the Services or the Company Content and other information contained
            therein. To the maximum extent permitted by Applicable Laws, we
            assume no liability or responsibility for any (a) errors, mistakes,
            or inaccuracies of content; (b) personal injury or property damage,
            of any nature whatsoever, resulting from your access to or use of
            our Sites, Company Content or the Services; (c) any unauthorized
            access to or use of our secure servers and/or any and all personal
            information stored therein; (d) any interruption or cessation of
            transmission to or from the Sites or the Services; (e) any bugs,
            viruses, trojan horses, or the like that may be transmitted to or
            through our Sites, Company Content or the Services by any third
            party; (f) any errors or omissions in any content or for any loss or
            damage incurred as a result of the use of any content posted,
            emailed, transmitted, or otherwise made available through the Sites
            or the Services; and/or (g) User Content or the defamatory,
            offensive, or illegal conduct of any third party. You agree that if,
            notwithstanding the other provisions of this Agreement, a Company
            Party is found to be liable for any claims, proceedings,
            liabilities, obligations, damages, losses or costs, such Company
            Party’s liability shall in no event exceed the amount you paid to us
            hereunder during the 1-month period prior to the date on which the
            event giving rise to such claim arose, if any.
          </p>
          <p>
            This limitation of liability section applies whether the alleged
            liability is based on contract, tort, negligence, strict liability,
            or any other basis, even if we have been advised of the possibility
            of such damage. The foregoing limitation of liability shall apply to
            the fullest extent permitted by Applicable Laws.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Limitations as Allowed by Law</Heading>
          <p>
            Some states, provinces and other jurisdictions do not allow the
            exclusion and limitations of certain implied warranties, or the
            exclusion or limitation of incidental or consequential damages, so
            the above limitations or exclusions may not apply to you. This
            Agreement gives you specific legal rights, and you may also have
            other rights which vary from jurisdiction to jurisdiction. The
            disclaimers, exclusions, and limitations of liability under this
            agreement will not apply to the extent prohibited by Applicable
            Laws.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Exclusions Fundamental to this Agreement</Heading>
          <p>
            The disclaimers of warranties and limitations of liability set forth
            in this Agreement are fundamental elements of the basis of the
            agreement between you and us. You understand and agree that we would
            not be able to economically or reasonably provide the Services to
            you without these limitations.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Non-Waiver</Heading>
          <p>
            Our failure or delay in insisting upon or enforcing strict
            performance of any provision of this Agreement shall not be
            construed as a waiver of any provision or rights. No waiver of any
            provision of this Agreement shall constitute a waiver of any other
            provision, nor shall any waiver of any provision of this Agreement
            constitute a continuing waiver unless expressly made in writing by
            us.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Severability</Heading>
          <p>
            In the event that any of the provisions of this Agreement are held
            by a court or other tribunal of competent jurisdiction to be
            unenforceable, such provisions shall be limited or eliminated to the
            minimum extent necessary so that these terms and conditions shall
            otherwise remain in full force and effect.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Governing Law and Jurisdiction</Heading>
          <p>
            This Agreement is entered into in the Republic of Panama and shall
            be governed by, and construed in accordance with, the laws of the
            Republic of Panama. You agree to submit to the exclusive
            jurisdiction of the courts of the Republic of Panama or any other
            judicial district or jurisdiction as we may determine in any and all
            actions, disputes or controversies relating hereto. You further
            agree as follows: (a) any claim brought to enforce this Agreement
            must be commenced within 2 years of the cause of action accruing;
            (b) no recovery may be sought or received for damages other than
            out-of-pocket expenses, except that the prevailing party will be
            entitled to costs and legal fees; (c) your sole and exclusive remedy
            shall be for monetary damages only and you hereby waive all rights
            to equitable remedies; and (d) any claim must be brought
            individually and not consolidated as part of a group or class action
            complaint.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Amendments</Heading>
          <p>
            We reserve the right, in our sole discretion, to modify the terms of
            this Agreement from time to time. If we make changes, we will
            provide you with notice of such changes, such as by providing notice
            through the Sites, the Services or updating the “Last Updated” date
            at the top of this Agreement. Unless we state otherwise in our
            notice, all such modifications are effective immediately, and your
            continued use of the Sites and the Services after we provide that
            notice will confirm your acceptance of the changes. If you do not
            agree to the amended terms of this Agreement, then your only
            recourse is to terminate this Agreement by providing written notice
            to us and must stop using the Sites and the Services.
          </p>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading subtitle>Entire Agreement</Heading>
          <p>
            This Agreement, including all other documents incorporated by
            reference herein, are binding and constitute the entire agreement
            between us and you with respect to your use of the Sites and the
            Services.
          </p>
        </Container>
      </Section>

      <Section className="mb-6">
        <Container>
          <Heading subtitle>Language</Heading>
          <p>
            The parties agree that the English language will be the language of
            the Agreement and all documents in connection thereto, and each
            party waives any right (whether statutory or otherwise) to use and
            rely upon any other language, or translations.{" "}
            <span className="is-italic">
              Il est de la volonté expresse des parties que tous les documents
              qui s'y rattachent soient rédigés en langue anglaise.
            </span>
          </p>
        </Container>
      </Section>
    </div>
  );
}
