// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__footer___y83rE {
  color: #000;
  background-color: #FFF;
}
.styles__footer-wrapper___NJgmP {
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
  .styles__footer-wrapper___NJgmP {
    padding: 0 20px;
  }
}
@media (min-width: 1440px) {
  .styles__footer-wrapper___NJgmP {
    padding: 0 1.38vw;
  }
}
.styles__footer-explore___BHOoj {
  display: flex;
  flex-direction: column;
  margin-top: 2.75rem;
}
@media (min-width: 1024px) {
  .styles__footer-explore___BHOoj {
    margin-top: 8rem;
    flex-direction: row;
  }
}
@media (min-width: 1280px) {
  .styles__footer-explore___BHOoj {
    margin-top: 8.88vw;
  }
}

.styles__links-section___BPbYr {
  width: 100%;
  margin-top: 3.5rem;
}
@media (min-width: 640px) {
  .styles__links-section___BPbYr {
    margin-top: 5rem;
  }
}
@media (min-width: 1024px) {
  .styles__links-section___BPbYr {
    width: 50%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/Footer/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,sBAAA;AACF;AACE;EACE,eAAA;EACA,cAAA;EACA,WAAA;AACJ;AACI;EALF;IAMI,eAAA;EAEJ;AACF;AAAI;EATF;IAUI,iBAAA;EAGJ;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAEJ;AAAI;EALF;IAMI,gBAAA;IACA,mBAAA;EAGJ;AACF;AADI;EAVF;IAWI,kBAAA;EAIJ;AACF;;AACE;EACE,WAAA;EACA,kBAAA;AAEJ;AAAI;EAJF;IAKI,gBAAA;EAGJ;AACF;AADI;EARF;IASI,UAAA;IACA,aAAA;IACA,aAAA;IACA,sBAAA;IACA,8BAAA;EAIJ;AACF","sourcesContent":[".footer {\n  color: #000;\n  background-color: #FFF;\n\n  &-wrapper {\n    padding: 0 16px;\n    margin: 0 auto;\n    width: 100%;\n\n    @media (min-width: 768px) {\n      padding: 0 20px;\n    }\n\n    @media (min-width: 1440px) {\n      padding: 0 1.38vw;\n    }\n  }\n\n  &-explore {\n    display: flex;\n    flex-direction: column;\n    margin-top: 2.75rem;\n\n    @media (min-width: 1024px) {\n      margin-top: 8rem;\n      flex-direction: row;\n    }\n\n    @media (min-width: 1280px) {\n      margin-top: 8.88vw;\n    }\n  }\n}\n\n.links {\n  &-section {\n    width: 100%;\n    margin-top: 3.5rem;\n  \n    @media (min-width: 640px) {\n      margin-top: 5rem;\n    }\n  \n    @media (min-width: 1024px) {\n      width: 50%;\n      margin-top: 0;\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `styles__footer___y83rE`,
	"footer-wrapper": `styles__footer-wrapper___NJgmP`,
	"footer-explore": `styles__footer-explore___BHOoj`,
	"links-section": `styles__links-section___BPbYr`
};
export default ___CSS_LOADER_EXPORT___;
