// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info {
  position: relative;
  cursor: pointer;
}
.info_message {
  position: absolute;
  top: 28px;
  right: 0;
  padding: 12px;
  max-width: 240px;
  width: max-content;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  color: #fff;
  background-color: rgba(46, 49, 54, 0.9);
}
.info:hover .info_message {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./app/components/auth/poh/UserVideoInfo/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;AACF;AACE;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EAEA,aAAA;EAEA,gBAAA;EACA,kBAAA;EAEA,UAAA;EACA,oBAAA;EACA,wBAAA;EAEA,WAAA;EACA,uCAAA;AAHJ;AAOI;EACE,UAAA;AALN","sourcesContent":[".info {\n  position: relative;\n  cursor: pointer;\n\n  &_message {\n    position: absolute;\n    top: 28px;\n    right: 0;\n\n    padding: 12px;\n\n    max-width: 240px;\n    width: max-content;\n\n    opacity: 0;\n    pointer-events: none;\n    transition: opacity 0.3s;\n\n    color: #fff;\n    background-color: rgba(46, 49, 54, 0.9);\n  }\n\n  &:hover {\n    .info_message {\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
