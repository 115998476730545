// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__title___z8Kit {
  color: #FFF;
  font-family: "Neue Montreal", sans-serif;
  line-height: 1;
  font-weight: bold;
  font-size: 4.125rem;
}

@media (min-width: 768px) {
  .styles__title___z8Kit {
    font-size: 15.375vw;
  }
}
@media (min-width: 1024px) {
  .styles__title___z8Kit {
    font-size: 8.375vw;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/IntroductorySection/Title/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,wCAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE;IACE,mBAAA;EACF;AACF;AAEA;EACE;IACE,kBAAA;EAAF;AACF","sourcesContent":[".title {\n  color: #FFF;\n  font-family: 'Neue Montreal', sans-serif;\n  line-height: 1;\n  font-weight: bold;\n  font-size: 4.125rem;\n}\n\n@media (min-width: 768px) {\n  .title {\n    font-size: 15.375vw;\n  }\n}\n\n@media (min-width: 1024px) {\n  .title {\n    font-size: 8.375vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles__title___z8Kit`
};
export default ___CSS_LOADER_EXPORT___;
