// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webcam-interface {
  position: relative;
  height: 100%;
}
.webcam-interface video {
  width: 100%;
}
.webcam-interface_pattern {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.webcam-interface_fade-text {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  font-size: 12px;
  white-space: nowrap;
  color: #fff;
}
.webcam-interface_fade-text._active {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .webcam-interface_fade-text {
    top: 35px;
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/auth/poh/WebcamInterface/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AAAJ;AAGE;EACE,kBAAA;EACA,SAAA;EACA,SAAA;EACA,2BAAA;EACA,UAAA;EAEA,UAAA;EACA,oCAAA;EAEA,eAAA;EACA,mBAAA;EAEA,WAAA;AAJJ;AAMI;EACE,UAAA;AAJN;;AASA;EAEI;IACE,SAAA;IACA,eAAA;EAPJ;AACF","sourcesContent":[".webcam-interface {\n  position: relative;\n  height: 100%;\n\n  & video {\n    width: 100%;\n  }\n\n  &_pattern {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n  }\n\n  &_fade-text {\n    position: absolute;\n    top: 100%;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: 2;\n\n    opacity: 0;\n    transition: opacity 0.4s ease-in-out;\n\n    font-size: 12px;\n    white-space: nowrap;\n\n    color: #fff;\n\n    &._active {\n      opacity: 1;\n    }\n  }\n}\n\n@media screen and (min-width: 768px) {\n  .webcam-interface {\n    &_fade-text {\n      top: 35px;\n      font-size: 14px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
