// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__content-logo___uBICM {
  height: 33px;
}
.styles__content-logo___uBICM svg {
  width: auto;
  height: 100%;
}
@media (min-width: 1440px) {
  .styles__content-logo___uBICM {
    height: 2.2vw;
  }
}
.styles__content-wrap___Q6LiN {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #000;
}
@media (min-width: 1440px) {
  .styles__content-wrap___Q6LiN {
    padding: 1.18vw 0;
    gap: 0.83vw;
  }
}
.styles__content-title___cNe4V {
  font-size: 24px;
  font-weight: bold;
}
@media (min-width: 1440px) {
  .styles__content-title___cNe4V {
    font-size: 1.6vw;
  }
}
.styles__content-text___rJbJY {
  font-size: 14px;
}
@media (min-width: 1440px) {
  .styles__content-text___rJbJY {
    font-size: 0.89vw;
  }
}
.styles__content-button___DPcxX {
  margin-top: 20px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.styles__content-button___DPcxX:hover {
  color: #000;
  background-color: #FF7538;
  border-color: #FF7538;
  transition: all 0.2s;
}
@media (min-width: 1440px) {
  .styles__content-button___DPcxX {
    margin-top: 1.38vw;
    padding: 0.83vw 1.38vw;
    border-width: 0.069vw;
    border-radius: 0.5vw;
    font-size: 1vw;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/TopBar/InfoAbout/PopupContent/styles.scss"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;AAAN;AAGI;EARF;IASI,aAAA;EAAJ;AACF;AAGE;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;AADJ;AAGI;EAPF;IAQI,iBAAA;IACA,WAAA;EAAJ;AACF;AAGE;EACE,eAAA;EACA,iBAAA;AADJ;AAGI;EAJF;IAKI,gBAAA;EAAJ;AACF;AAGE;EACE,eAAA;AADJ;AAGI;EAHF;IAII,iBAAA;EAAJ;AACF;AAGE;EACE,gBAAA;EACA,kBAAA;EACA,sBAAA;EAEA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,WAAA;EAEA,eAAA;EACA,eAAA;AAHJ;AAKI;EACE,WAAA;EACA,yBAAA;EACA,qBAAA;EACA,oBAAA;AAHN;AAMI;EApBF;IAqBI,kBAAA;IACA,sBAAA;IAEA,qBAAA;IACA,oBAAA;IAEA,cAAA;EALJ;AACF","sourcesContent":[".content {\n  &-logo {\n    height: 33px;\n\n    & svg {\n      width: auto;\n      height: 100%;\n    }\n\n    @media (min-width: 1440px) {\n      height: 2.2vw;\n    }\n  }\n\n  &-wrap {\n    padding: 20px 0;\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n    color: #000;\n\n    @media (min-width: 1440px) {\n      padding: 1.18vw 0;\n      gap: 0.83vw;\n    }\n  }\n\n  &-title {\n    font-size: 24px;\n    font-weight: bold;\n\n    @media (min-width: 1440px) {\n      font-size: 1.6vw;\n    }\n  }\n\n  &-text {\n    font-size: 14px;\n\n    @media (min-width: 1440px) {\n      font-size: 0.89vw;\n    }\n  }\n\n  &-button {\n    margin-top: 20px;\n    padding: 12px 20px;\n    box-sizing: border-box;\n\n    border: none;\n    border-radius: 8px;\n    background-color: #000;\n    color: #fff;\n\n    font-size: 16px;\n    cursor: pointer;\n\n    &:hover {\n      color: #000;\n      background-color: #FF7538;\n      border-color: #FF7538;\n      transition: all 0.2s;\n    }\n\n    @media (min-width: 1440px) {\n      margin-top: 1.38vw;\n      padding: 0.83vw 1.38vw;\n\n      border-width: 0.069vw;\n      border-radius: 0.5vw;\n\n      font-size: 1vw;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-logo": `styles__content-logo___uBICM`,
	"content-wrap": `styles__content-wrap___Q6LiN`,
	"content-title": `styles__content-title___cNe4V`,
	"content-text": `styles__content-text___rJbJY`,
	"content-button": `styles__content-button___DPcxX`
};
export default ___CSS_LOADER_EXPORT___;
