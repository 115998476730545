// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videocam-button {
  padding: 12px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 24px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #494949;
  background-color: #f1f1f1;
}
.videocam-button svg {
  margin-left: 6px;
}
.videocam-button:hover {
  background-color: #e7e7e7;
}`, "",{"version":3,"sources":["webpack://./app/components/auth/poh/RecordPreviewButton/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,sBAAA;EAEA,aAAA;EACA,mBAAA;EAEA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,oBAAA;EAEA,kCAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EAEA,yBAAA;AAHF;AAKE;EACE,gBAAA;AAHJ;AAME;EACE,yBAAA;AAJJ","sourcesContent":[".videocam-button {\n  padding: 12px 20px;\n  box-sizing: border-box;\n\n  display: flex;\n  align-items: center;\n\n  border-radius: 24px;\n  border: none;\n  outline: none;\n  cursor: pointer;\n  transition: all 0.2s;\n\n  font-family: \"Poppins\", sans-serif;\n  font-weight: 600;\n  font-size: 16px;\n  color: #494949;\n\n  background-color: #f1f1f1;\n\n  & svg {\n    margin-left: 6px;\n  }\n\n  &:hover {\n    background-color: #e7e7e7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
