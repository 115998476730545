// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__links-container___nnutA {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.styles__links-list___gxZ3c {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  align-items: flex-start;
}
@media (min-width: 640px) {
  .styles__links-list___gxZ3c {
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }
}
@media (min-width: 1280px) {
  .styles__links-list___gxZ3c {
    gap: 3.3vw;
  }
}
.styles__links-extra___ylVMA {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  text-align: right;
}
@media (min-width: 1280px) {
  .styles__links-extra___ylVMA {
    gap: 0.97vw;
  }
}
.styles__links-divider___GP0pA {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.styles__links-link___mCvPW {
  font-size: 1.125rem;
  color: #000;
}
.styles__links-link___mCvPW:hover {
  color: #FF6F3E;
}
@media (min-width: 1280px) {
  .styles__links-link___mCvPW {
    font-size: 1.25vw;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/Footer/LinksList/styles.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,8BAAA;AAAJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;AADJ;AAGI;EANF;IAOI,mBAAA;IACA,mBAAA;IACA,SAAA;EAAJ;AACF;AAEI;EAZF;IAaI,UAAA;EACJ;AACF;AAEE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,iBAAA;AAAJ;AAEI;EANF;IAOI,WAAA;EACJ;AACF;AAEE;EACE,oBAAA;EACA,qBAAA;AAAJ;AAGE;EACE,mBAAA;EACA,WAAA;AADJ;AAGI;EACE,cAAA;AADN;AAII;EARF;IASI,iBAAA;EADJ;AACF","sourcesContent":[".links {\n  &-container {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    justify-content: space-between;\n  }\n  \n  &-list {\n    display: flex;\n    flex-direction: column;\n    gap: 0.875rem;\n    align-items: flex-start;\n\n    @media (min-width: 640px) {\n      flex-direction: row;\n      align-items: center;\n      gap: 2rem;\n    }\n\n    @media (min-width: 1280px) {\n      gap: 3.3vw;\n    }\n  } \n\n  &-extra {\n    display: flex;\n    flex-direction: column;\n    gap: 0.875rem;\n    text-align: right;\n\n    @media (min-width: 1280px) {\n      gap: 0.97vw;\n    }\n  }\n\n  &-divider {\n    padding-left: 0.5rem;\n    padding-right: 0.5rem;\n  }\n\n  &-link {\n    font-size: 1.125rem;\n    color: #000;\n\n    &:hover {\n      color: #FF6F3E;\n    }\n\n    @media (min-width: 1280px) {\n      font-size: 1.25vw;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"links-container": `styles__links-container___nnutA`,
	"links-list": `styles__links-list___gxZ3c`,
	"links-extra": `styles__links-extra___ylVMA`,
	"links-divider": `styles__links-divider___GP0pA`,
	"links-link": `styles__links-link___mCvPW`
};
export default ___CSS_LOADER_EXPORT___;
