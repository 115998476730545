import React from 'react';

export const LogoWithTextSvg = () => (
  <svg width="133" height="40" viewBox="0 0 133 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.9245 0H18.0094L19.1839 1.17277V8.98143C19.1839 9.29438 19.0624 9.59099 18.8404 9.81151C17.4356 11.2065 12.0859 16.5203 9.60913 18.9933C9.30334 19.2986 9.30334 19.7936 9.60913 20.099C12.0858 22.5719 17.4356 27.8857 18.8404 29.2807C19.0624 29.5013 19.1839 29.7979 19.1839 30.1108V37.9195L17.8136 39.0922H21.1415L19.9669 37.9195V30.1089C19.9669 29.7971 20.0909 29.498 20.3116 29.2777L29.3225 20.2804C29.5427 20.0605 29.8411 19.937 30.1523 19.937H37.585L39.151 21.1098V17.9824L37.585 19.1552H30.1523C29.8411 19.1552 29.5427 19.0317 29.3225 18.8118L20.3116 9.81451C20.0909 9.59419 19.9669 9.29517 19.9669 8.98336V1.17277L21.9245 0Z" fill="#FF6338"/>
    <path d="M18.9862 9.6799C19.3263 9.34039 19.8776 9.34039 20.2176 9.6799L29.4831 18.9314C29.8231 19.2709 29.8231 19.8214 29.4831 20.1609L20.191 29.4389C19.851 29.7784 19.2997 29.7784 18.9597 29.4389L9.6942 20.1874C9.35418 19.8479 9.35418 19.2974 9.6942 18.9579L18.9862 9.6799Z" fill="#030303"/>
    <path d="M7.83025 0C3.50572 0 0 3.50044 0 7.81845V31.2738C0 35.5918 3.50572 39.0922 7.83025 39.0922H18.0096C18.6583 39.0922 19.1841 38.5664 19.1841 37.9177V30.1096C19.1841 29.7974 19.0629 29.5015 18.8412 29.2813C17.4374 27.8872 12.0862 22.5723 9.60913 20.099C9.30334 19.7936 9.30334 19.2986 9.60913 18.9933C12.0862 16.5199 17.4374 11.205 18.8412 9.8109C19.0629 9.59076 19.1841 9.29486 19.1841 8.98268V1.17454C19.1841 0.525858 18.658 0 18.0094 0H7.83025Z" fill="white"/>
    <path d="M21.1417 0C20.493 0 19.9671 0.525858 19.9671 1.17454V8.98461C19.9671 9.29565 20.0909 9.59394 20.3112 9.81388L29.3225 18.8118C29.5427 19.0317 29.8416 19.1552 30.1531 19.1552H37.9767C38.6254 19.1552 39.1512 18.6293 39.1512 17.9807V7.81845C39.1512 3.50044 35.6455 0 31.321 0H21.1417Z" fill="white"/>
    <path d="M39.1512 21.1116C39.1512 20.4629 38.6254 19.937 37.9767 19.937H30.1531C29.8416 19.937 29.5427 20.0605 29.3225 20.2804L20.3112 29.2784C20.0909 29.4983 19.9671 29.7966 19.9671 30.1076V37.9177C19.9671 38.5664 20.4928 39.0922 21.1415 39.0922H31.321C35.6455 39.0922 39.1512 35.5918 39.1512 31.2738V21.1116Z" fill="white"/>
    <path d="M49.125 27.1388V11.3926H55.1152C57.3028 11.3926 59.0132 12.0753 60.2464 13.4407C61.4944 14.7915 62.1184 16.7368 62.1184 19.2767C62.1184 21.802 61.5017 23.7473 60.2685 25.1127C59.0499 26.4634 57.3468 27.1388 55.1592 27.1388H49.125ZM51.5475 13.4848V25.0466H55.0051C55.9153 25.0466 56.6714 24.8925 57.2734 24.5842C57.8753 24.2759 58.3452 23.8501 58.6828 23.3069C59.0205 22.7636 59.2554 22.147 59.3876 21.457C59.5344 20.7669 59.6078 20.0402 59.6078 19.2767C59.6078 18.5133 59.5344 17.7865 59.3876 17.0965C59.2554 16.4065 59.0205 15.7898 58.6828 15.2466C58.3452 14.7034 57.8753 14.2776 57.2734 13.9693C56.6714 13.6463 55.9153 13.4848 55.0051 13.4848H51.5475Z" fill="white"/>
    <path d="M69.0153 27.4691C67.8261 27.4691 66.8131 27.2122 65.9762 26.6983C65.1541 26.1698 64.5301 25.4577 64.1043 24.5622C63.6785 23.6666 63.4657 22.6462 63.4657 21.501C63.4657 20.3265 63.6859 19.2914 64.1263 18.3958C64.5815 17.5002 65.2201 16.8029 66.0423 16.3037C66.8792 15.8045 67.8702 15.5549 69.0153 15.5549C70.7184 15.5549 72.0324 16.0761 72.9574 17.1185C73.8823 18.1462 74.3448 19.5337 74.3448 21.2808C74.3448 21.4276 74.3448 21.5818 74.3448 21.7433C74.3448 21.8901 74.3375 22.0075 74.3228 22.0956H65.6899C65.7046 22.7269 65.8367 23.3215 66.0863 23.8795C66.3359 24.4227 66.7103 24.8631 67.2095 25.2008C67.7087 25.5385 68.3253 25.7073 69.0594 25.7073C69.7201 25.7073 70.2486 25.5899 70.645 25.355C71.0561 25.1201 71.3644 24.8484 71.57 24.5401C71.7902 24.2171 71.937 23.9308 72.0104 23.6812H74.1907C73.9117 24.753 73.3465 25.6559 72.4949 26.39C71.6581 27.1094 70.4982 27.4691 69.0153 27.4691ZM68.9493 17.3167C68.0537 17.3167 67.3049 17.603 66.703 18.1756C66.101 18.7335 65.7633 19.4749 65.6899 20.3999H72.0765C72.0765 19.519 71.7902 18.7849 71.2176 18.1976C70.6597 17.6104 69.9036 17.3167 68.9493 17.3167Z" fill="white"/>
    <path d="M83.7396 19.9815C83.7103 19.585 83.6075 19.1886 83.4313 18.7922C83.2551 18.3811 82.9615 18.0435 82.5504 17.7792C82.154 17.5002 81.6108 17.3608 80.9207 17.3608C80.1279 17.3608 79.4893 17.559 79.0048 17.9554C78.535 18.3518 78.1899 18.873 77.9697 19.519C77.7642 20.1503 77.6614 20.8183 77.6614 21.523C77.6614 22.2278 77.7642 22.8958 77.9697 23.5271C78.1899 24.1584 78.535 24.6723 79.0048 25.0687C79.4893 25.4651 80.1279 25.6633 80.9207 25.6633C81.6108 25.6633 82.154 25.5311 82.5504 25.2669C82.9615 24.9879 83.2551 24.6429 83.4313 24.2318C83.6075 23.8207 83.7103 23.4023 83.7396 22.9765H85.9859C85.9419 23.8281 85.6996 24.5989 85.2592 25.2889C84.8187 25.9643 84.2241 26.5001 83.4754 26.8965C82.7413 27.2783 81.8971 27.4691 80.9428 27.4691C79.827 27.4691 78.8506 27.2195 78.0138 26.7204C77.1916 26.2212 76.5529 25.5238 76.0978 24.6282C75.6427 23.7326 75.4151 22.6976 75.4151 21.523C75.4151 20.3485 75.6427 19.3134 76.0978 18.4179C76.5529 17.5223 77.1916 16.8249 78.0138 16.3257C78.8506 15.8265 79.8196 15.5769 80.9207 15.5769C81.8897 15.5769 82.7339 15.7678 83.4533 16.1495C84.1874 16.5313 84.7674 17.0525 85.1931 17.7131C85.6336 18.3738 85.8978 19.1299 85.9859 19.9815H83.7396Z" fill="white"/>
    <path d="M87.3078 11.5688H89.7523V13.9032H87.3078V11.5688ZM87.4619 15.9073H89.6201V27.1388H87.4619V15.9073Z" fill="white"/>
    <path d="M99.6418 17.2727H99.6858V11.3926H101.866V27.1388H99.6858V25.8174H99.6418C99.3481 26.2138 98.915 26.5882 98.3424 26.9406C97.7845 27.2783 97.0651 27.4471 96.1842 27.4471C95.2299 27.4471 94.371 27.2122 93.6076 26.7424C92.8588 26.2726 92.2642 25.5972 91.8237 24.7163C91.3833 23.8207 91.1631 22.7563 91.1631 21.523C91.1631 20.2898 91.3833 19.2327 91.8237 18.3518C92.2642 17.4562 92.8588 16.7735 93.6076 16.3037C94.371 15.8192 95.2299 15.5769 96.1842 15.5769C97.0651 15.5769 97.7845 15.7531 98.3424 16.1055C98.915 16.4432 99.3481 16.8322 99.6418 17.2727ZM96.5366 25.6633C97.5056 25.6633 98.269 25.2889 98.8269 24.5401C99.3995 23.7914 99.6858 22.7857 99.6858 21.523C99.6858 20.2457 99.3995 19.24 98.8269 18.5059C98.269 17.7572 97.5056 17.3828 96.5366 17.3828C95.8318 17.3828 95.2446 17.5737 94.7748 17.9554C94.3196 18.3371 93.9746 18.8436 93.7397 19.4749C93.5195 20.1063 93.4094 20.7889 93.4094 21.523C93.4094 22.2571 93.5195 22.9398 93.7397 23.5711C93.9746 24.2024 94.3196 24.709 94.7748 25.0907C95.2446 25.4724 95.8318 25.6633 96.5366 25.6633Z" fill="white"/>
    <path d="M108.963 27.4691C107.774 27.4691 106.761 27.2122 105.924 26.6983C105.102 26.1698 104.478 25.4577 104.052 24.5622C103.626 23.6666 103.414 22.6462 103.414 21.501C103.414 20.3265 103.634 19.2914 104.074 18.3958C104.529 17.5002 105.168 16.8029 105.99 16.3037C106.827 15.8045 107.818 15.5549 108.963 15.5549C110.666 15.5549 111.98 16.0761 112.905 17.1185C113.83 18.1462 114.293 19.5337 114.293 21.2808C114.293 21.4276 114.293 21.5818 114.293 21.7433C114.293 21.8901 114.285 22.0075 114.271 22.0956H105.638C105.653 22.7269 105.785 23.3215 106.034 23.8795C106.284 24.4227 106.658 24.8631 107.157 25.2008C107.657 25.5385 108.273 25.7073 109.007 25.7073C109.668 25.7073 110.197 25.5899 110.593 25.355C111.004 25.1201 111.312 24.8484 111.518 24.5401C111.738 24.2171 111.885 23.9308 111.958 23.6812H114.139C113.86 24.753 113.294 25.6559 112.443 26.39C111.606 27.1094 110.446 27.4691 108.963 27.4691ZM108.897 17.3167C108.002 17.3167 107.253 17.603 106.651 18.1756C106.049 18.7335 105.711 19.4749 105.638 20.3999H112.024C112.024 19.519 111.738 18.7849 111.166 18.1976C110.608 17.6104 109.852 17.3167 108.897 17.3167Z" fill="white"/>
    <path d="M125.427 23.3509H118.843L117.543 27.1388H114.989L120.847 11.3926H123.467L129.369 27.1388H126.727L125.427 23.3509ZM119.547 21.3028H124.723L122.168 13.639H122.124L119.547 21.3028Z" fill="white"/>
    <path d="M130.578 11.3926H133V27.1388H130.578V11.3926Z" fill="white"/>
  </svg>
);

export const SingleLogoSvg = () => (
  <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M143.36 0H117.76L125.44 7.68V60.7703C125.44 61.5689 125.148 62.3088 124.581 62.8719C118.365 69.054 80.0199 107.193 62.8331 124.38C60.8336 126.379 60.8336 129.621 62.8331 131.62C80.0198 148.807 118.365 186.946 124.581 193.128C125.148 193.691 125.44 194.431 125.44 195.23V248.32L116.48 256H138.24L130.56 248.32V195.225C130.56 194.429 130.876 193.666 131.439 193.103L193.103 131.439C193.666 130.876 194.429 130.56 195.225 130.56H245.76L256 138.24V117.76L245.76 125.44H195.225C194.429 125.44 193.666 125.124 193.103 124.561L131.439 62.8966C130.876 62.334 130.56 61.5709 130.56 60.7753V7.68L143.36 0Z" fill="white"/>
    <path d="M51.2 0C22.923 0 0 22.923 0 51.2V204.8C0 233.077 22.923 256 51.2 256H122.44C124.097 256 125.44 254.657 125.44 253V197.176C125.44 195.132 124.647 193.194 123.198 191.752C114.019 182.623 79.03 147.817 62.8331 131.62C60.8336 129.621 60.8336 126.379 62.8331 124.38C79.0301 108.183 114.019 73.3772 123.198 64.2478C124.647 62.8062 125.44 60.8685 125.44 58.8241V3C125.44 1.34315 124.097 0 122.44 0H51.2Z" fill="#030303"/>
    <path d="M133.56 0C131.903 0 130.56 1.34314 130.56 3V58.8368C130.56 60.8736 131.369 62.827 132.809 64.2673L191.733 123.191C193.173 124.631 195.126 125.44 197.163 125.44H253C254.657 125.44 256 124.097 256 122.44V51.2C256 22.923 233.077 0 204.8 0H133.56Z" fill="#030303"/>
    <path d="M256 133.56C256 131.903 254.657 130.56 253 130.56H197.163C195.126 130.56 193.173 131.369 191.733 132.809L132.809 191.733C131.369 193.173 130.56 195.126 130.56 197.163V253C130.56 254.657 131.903 256 133.56 256H204.8C233.077 256 256 233.077 256 204.8V133.56Z" fill="#030303"/>
  </svg>
);

export const LogoWithTextBlackSvg = () => (
  <svg width="538" height="158" viewBox="0 0 538 158" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M88.6144 0H72.7905L77.5377 4.74V36.3013C77.5377 37.5656 77.0469 38.7639 76.1498 39.6549C70.4731 45.2923 48.8497 66.77 38.8391 76.7655C37.6032 77.9996 37.6032 80.0004 38.8391 81.2345C48.8496 91.2299 70.4731 112.708 76.1498 118.345C77.0469 119.236 77.5377 120.434 77.5377 121.699V153.26L71.9993 158H85.4496L80.7024 153.26V121.691C80.7024 120.431 81.2034 119.223 82.0948 118.333L118.515 81.9673C119.405 81.079 120.611 80.58 121.868 80.58H151.91L158.239 85.32V72.68L151.91 77.42H121.868C120.611 77.42 119.405 76.921 118.515 76.0327L82.0948 39.667C81.2034 38.7768 80.7024 37.5688 80.7024 36.3091V4.74L88.6144 0Z" fill="white"/>
    <path d="M31.6477 0C14.1692 0 0 14.1478 0 31.6V126.4C0 143.852 14.1692 158 31.6477 158H72.7917C75.4124 158 77.5369 155.876 77.5369 153.255V121.694C77.5369 120.433 77.0469 119.237 76.1509 118.347C70.4772 112.712 48.8507 91.231 38.8391 81.2345C37.6032 80.0004 37.6032 77.9996 38.8391 76.7655C48.8508 66.7689 70.4772 45.2875 76.1509 39.653C77.0469 38.7632 77.5369 37.5673 77.5369 36.3055V4.7452C77.5369 2.1245 75.4124 0 72.7917 0H31.6477Z" fill="#030303"/>
    <path d="M85.4469 0C82.8262 0 80.7017 2.12449 80.7017 4.74519V36.3133C80.7017 37.5704 81.2018 38.7761 82.0921 39.665L118.514 76.0317C119.404 76.9206 120.611 77.42 121.87 77.42H153.493C156.114 77.42 158.239 75.2955 158.239 72.6748V31.6C158.239 14.1478 144.069 0 126.591 0H85.4469Z" fill="#030303"/>
    <path d="M158.239 85.3252C158.239 82.7045 156.114 80.58 153.493 80.58H121.87C120.611 80.58 119.404 81.0794 118.514 81.9683L82.0921 118.335C81.2018 119.224 80.7017 120.43 80.7017 121.687V153.255C80.7017 155.876 82.8262 158 85.4469 158H126.591C144.069 158 158.239 143.852 158.239 126.4V85.3252Z" fill="#030303"/>
    <path d="M198.547 109.687V46.0721H222.748C231.586 46.0721 238.496 48.8303 243.479 54.3466C248.52 59.8036 251.041 67.6628 251.041 77.9243C251.041 88.1265 248.55 95.9857 243.568 101.502C238.644 106.959 231.764 109.687 222.926 109.687H198.547ZM208.334 54.5245V101.235H222.303C225.981 101.235 229.035 100.612 231.467 99.3667C233.899 98.121 235.797 96.4009 237.162 94.2063C238.526 92.0116 239.475 89.5204 240.009 86.7326C240.602 83.9448 240.898 81.0087 240.898 77.9243C240.898 74.8399 240.602 71.9038 240.009 69.116C239.475 66.3282 238.526 63.837 237.162 61.6423C235.797 59.4477 233.899 57.7275 231.467 56.4819C229.035 55.177 225.981 54.5245 222.303 54.5245H208.334Z" fill="#030303"/>
    <path d="M278.905 111.022C274.101 111.022 270.008 109.984 266.627 107.908C263.306 105.773 260.785 102.896 259.065 99.2777C257.344 95.6595 256.484 91.5371 256.484 86.9105C256.484 82.1653 257.374 77.9836 259.154 74.3654C260.992 70.7472 263.573 67.9297 266.894 65.913C270.275 63.8963 274.279 62.8879 278.905 62.8879C285.786 62.8879 291.095 64.9936 294.832 69.205C298.568 73.357 300.437 78.9623 300.437 86.0208C300.437 86.6139 300.437 87.2367 300.437 87.8892C300.437 88.4824 300.407 88.9569 300.348 89.3128H265.471C265.53 91.8633 266.064 94.2656 267.072 96.5195C268.08 98.7142 269.593 100.494 271.61 101.858C273.626 103.222 276.118 103.904 279.083 103.904C281.753 103.904 283.888 103.43 285.489 102.481C287.15 101.532 288.396 100.434 289.226 99.1887C290.116 97.8838 290.709 96.7271 291.006 95.7188H299.814C298.687 100.049 296.403 103.697 292.963 106.662C289.582 109.569 284.896 111.022 278.905 111.022ZM278.639 70.0057C275.02 70.0057 271.995 71.1624 269.563 73.4757C267.131 75.7296 265.767 78.725 265.471 82.4619H291.273C291.273 78.903 290.116 75.9372 287.803 73.5646C285.549 71.192 282.494 70.0057 278.639 70.0057Z" fill="#030303"/>
    <path d="M338.392 80.7714C338.274 79.1699 337.859 77.5684 337.147 75.9669C336.435 74.3061 335.249 72.9418 333.588 71.8742C331.986 70.7472 329.792 70.1837 327.004 70.1837C323.801 70.1837 321.221 70.9844 319.263 72.5859C317.365 74.1875 315.971 76.2931 315.082 78.903C314.251 81.4535 313.836 84.1524 313.836 86.9995C313.836 89.8466 314.251 92.5454 315.082 95.096C315.971 97.6465 317.365 99.7226 319.263 101.324C321.221 102.926 323.801 103.726 327.004 103.726C329.792 103.726 331.986 103.192 333.588 102.125C335.249 100.998 336.435 99.6039 337.147 97.9431C337.859 96.2823 338.274 94.5918 338.392 92.8717H347.468C347.29 96.3119 346.311 99.426 344.532 102.214C342.752 104.942 340.35 107.107 337.325 108.709C334.359 110.251 330.948 111.022 327.093 111.022C322.585 111.022 318.641 110.014 315.26 107.997C311.938 105.98 309.358 103.163 307.519 99.5446C305.68 95.9264 304.761 91.7447 304.761 86.9995C304.761 82.2543 305.68 78.0726 307.519 74.4544C309.358 70.8361 311.938 68.0187 315.26 66.002C318.641 63.9853 322.555 62.9769 327.004 62.9769C330.919 62.9769 334.329 63.748 337.236 65.2902C340.202 66.8324 342.544 68.9381 344.265 71.6072C346.044 74.2764 347.112 77.3311 347.468 80.7714H338.392Z" fill="#030303"/>
    <path d="M352.808 46.7839H362.684V56.215H352.808V46.7839ZM353.431 64.3115H362.15V109.687H353.431V64.3115Z" fill="#030303"/>
    <path d="M402.638 69.8278H402.816V46.0721H411.624V109.687H402.816V104.349H402.638C401.452 105.951 399.702 107.463 397.389 108.887C395.135 110.251 392.228 110.933 388.669 110.933C384.814 110.933 381.344 109.984 378.259 108.086C375.234 106.188 372.832 103.459 371.053 99.9005C369.273 96.2823 368.384 91.9819 368.384 86.9995C368.384 82.017 369.273 77.7463 371.053 74.1874C372.832 70.5692 375.234 67.8111 378.259 65.913C381.344 63.9556 384.814 62.9769 388.669 62.9769C392.228 62.9769 395.135 63.6887 397.389 65.1123C399.702 66.4765 401.452 68.0483 402.638 69.8278ZM390.093 103.726C394.008 103.726 397.092 102.214 399.346 99.1887C401.659 96.1637 402.816 92.1006 402.816 86.9995C402.816 81.8391 401.659 77.776 399.346 74.8103C397.092 71.7852 394.008 70.2727 390.093 70.2727C387.246 70.2727 384.873 71.0438 382.975 72.5859C381.136 74.1281 379.742 76.1745 378.793 78.725C377.904 81.2756 377.459 84.0337 377.459 86.9995C377.459 89.9652 377.904 92.7234 378.793 95.2739C379.742 97.8245 381.136 99.8708 382.975 101.413C384.873 102.955 387.246 103.726 390.093 103.726Z" fill="#030303"/>
    <path d="M440.297 111.022C435.493 111.022 431.4 109.984 428.019 107.908C424.698 105.773 422.177 102.896 420.457 99.2777C418.736 95.6595 417.876 91.5371 417.876 86.9105C417.876 82.1653 418.766 77.9836 420.546 74.3654C422.384 70.7472 424.965 67.9297 428.286 65.913C431.667 63.8963 435.671 62.8879 440.297 62.8879C447.178 62.8879 452.487 64.9936 456.224 69.205C459.96 73.357 461.829 78.9623 461.829 86.0208C461.829 86.6139 461.829 87.2367 461.829 87.8892C461.829 88.4824 461.799 88.9569 461.74 89.3128H426.863C426.922 91.8633 427.456 94.2656 428.464 96.5195C429.472 98.7142 430.985 100.494 433.002 101.858C435.018 103.222 437.51 103.904 440.475 103.904C443.145 103.904 445.28 103.43 446.881 102.481C448.542 101.532 449.788 100.434 450.618 99.1887C451.508 97.8838 452.101 96.7271 452.398 95.7188H461.206C460.079 100.049 457.795 103.697 454.355 106.662C450.974 109.569 446.288 111.022 440.297 111.022ZM440.031 70.0057C436.412 70.0057 433.387 71.1624 430.955 73.4757C428.523 75.7296 427.159 78.725 426.863 82.4619H452.665C452.665 78.903 451.508 75.9372 449.195 73.5646C446.941 71.192 443.886 70.0057 440.031 70.0057Z" fill="#030303"/>
    <path d="M506.813 94.3842H480.21L474.961 109.687H464.64L488.307 46.0721H498.895L522.739 109.687H512.063L506.813 94.3842ZM483.058 86.1098H503.966L493.645 55.1473H493.467L483.058 86.1098Z" fill="#030303"/>
    <path d="M527.62 46.0721H537.407V109.687H527.62V46.0721Z" fill="#030303"/>
  </svg>
);