export const initialState = {
  decimals: 1, // impossible to divide by zero
  transactionFee: 0,
  loginPrincipalId: null,
  userProfile: null,
  requiresSignUp: false,
  isAdminUser: false,
  systemBalance: 0,
  personalBalance: 0,
  lockedBalance: 0,
  pendingStakeList: [],
  canClaimRewards: false,
  claimRewardAmount: 0,
  claimRewardPrice: 0,
  stakeBalance: 0,
  rs: { level: "", score: 0 },
  rsLoading: false,
  leaderboardContent: [],
  contentModerationTasks: [],
  moderationTasksPage: 1,
  moderationTasksPageStartIndex: 0,
  moderationTasksPageSize: 20,
  unlockStakeBalance: 0,
  claimedStakeBalance: 0,
  pendingStakeBalance: 0,
  providerBalance: 0,
  providerBufferBalance: 0,
  pohReservedContent: null,
  contentReservedTime: 0,
  selectedProvider: null,
  userProviders: [],
  contentProvidersFilter: null,
  contentCategoriesFilter: null,
  contentCategories: [],
  contentProviders: [],
  // === Loaders
  pendingStakeListLoading: true,
  pendingStakeLoading: true,
  systemBalanceLoading: true,
  personalBalanceLoading: true,
  stakeBalanceLoading: true,
  lockedBalanceLoading: true,
  releaseUnStakedLoading: false,
  unlockStakeLoading: true,
  providerBalanceLoading: true,
  providerBufferBalanceLoading: true,
  moderationTasksLoading: false,
  claimedStakeLoading: true,
  // === System Signals
  errors: [],
  notifications: [],
  // === Mutations state
  accountDepositAction: null,
  accountWithdrawAction: null,
  claimRewardsAction: null,
  stakeTokensAction: null,
  unstakeTokensAction: null,
};
