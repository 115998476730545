// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__tagline-container___M2sxE {
  display: flex;
  align-items: center;
  padding-top: 2.5rem;
  gap: 1.25rem;
}
@media (min-width: 768px) {
  .styles__tagline-container___M2sxE {
    gap: 3.5rem;
    padding-top: 3.5rem;
  }
}
.styles__tagline-logo___XKoDr {
  width: 64px;
  height: 64px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .styles__tagline-logo___XKoDr {
    width: 180px;
    height: 180px;
  }
}
@media (min-width: 1280px) {
  .styles__tagline-logo___XKoDr {
    width: 8.5vw;
    height: 8.5vw;
  }
}
.styles__tagline-logo___XKoDr svg {
  width: 100%;
  height: 100%;
}
.styles__tagline-info___WRutR {
  width: 100%;
  max-width: 550px;
}
@media (min-width: 1280px) {
  .styles__tagline-info___WRutR {
    max-width: 28.19vw;
  }
}
.styles__tagline-text____7WaY {
  color: #000;
  line-height: 1;
  font-size: 1.875rem;
}
@media (min-width: 768px) {
  .styles__tagline-text____7WaY {
    font-size: 4.5rem;
  }
}
@media (min-width: 1280px) {
  .styles__tagline-text____7WaY {
    font-size: 2.8vw;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/Footer/LogoTagline/styles.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AAAJ;AAEI;EANF;IAOI,WAAA;IACA,mBAAA;EACJ;AACF;AAEE;EACE,WAAA;EACA,YAAA;EACA,cAAA;AAAJ;AAEI;EALF;IAMI,YAAA;IACA,aAAA;EACJ;AACF;AACI;EAVF;IAWI,YAAA;IACA,aAAA;EAEJ;AACF;AAAI;EACE,WAAA;EACA,YAAA;AAEN;AAEE;EACE,WAAA;EACA,gBAAA;AAAJ;AAEI;EAJF;IAKI,kBAAA;EACJ;AACF;AAEE;EACE,WAAA;EACA,cAAA;EACA,mBAAA;AAAJ;AAEI;EALF;IAMI,iBAAA;EACJ;AACF;AACI;EATF;IAUI,gBAAA;EAEJ;AACF","sourcesContent":[".tagline {\n  &-container {\n    display: flex;\n    align-items: center;\n    padding-top: 2.5rem;\n    gap: 1.25rem;\n  \n    @media (min-width: 768px) {\n      gap: 3.5rem;\n      padding-top: 3.5rem;\n    }\n  }\n\n  &-logo {\n    width: 64px;\n    height: 64px;\n    flex-shrink: 0;\n  \n    @media (min-width: 768px) {\n      width: 180px;\n      height: 180px;\n    }\n  \n    @media (min-width: 1280px) {\n      width: 8.5vw;\n      height: 8.5vw;\n    }\n\n    & svg {\n      width: 100%;\n      height: 100%;\n    }\n  }\n  \n  &-info {\n    width: 100%;\n    max-width: 550px;\n  \n    @media (min-width: 1280px) {\n      max-width: 28.19vw;\n    }\n  }\n\n  &-text {\n    color: #000;\n    line-height: 1;\n    font-size: 1.875rem;\n  \n    @media (min-width: 768px) {\n      font-size: 4.5rem;\n    }\n  \n    @media (min-width: 1280px) {\n      font-size: 2.8vw;\n    }\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagline-container": `styles__tagline-container___M2sxE`,
	"tagline-logo": `styles__tagline-logo___XKoDr`,
	"tagline-info": `styles__tagline-info___WRutR`,
	"tagline-text": `styles__tagline-text____7WaY`
};
export default ___CSS_LOADER_EXPORT___;
