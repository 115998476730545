// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__ticker-container___cc6bX {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}
.styles__ticker-animate___sCBhC {
  display: flex;
  gap: 1.25rem;
  align-items: center;
  animation: styles__ticker___GW3jU 50s linear infinite;
}

@keyframes styles__ticker___GW3jU {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/Footer/Decor/TickerAnimation/styles.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AAAJ;AAGE;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,qDAAA;AADJ;;AAKA;EACE;IACE,wBAAA;EAFF;EAIA;IACE,4BAAA;EAFF;AACF","sourcesContent":[".ticker {\n  &-container {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    height: 100%;\n  }\n\n  &-animate {\n    display: flex;\n    gap: 1.25rem;\n    align-items: center;\n    animation: ticker 50s linear infinite;\n  }\n}\n\n@keyframes ticker {\n  from {\n    transform: translateX(0);\n  }\n  to {\n    transform: translateX(-100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ticker-container": `styles__ticker-container___cc6bX`,
	"ticker-animate": `styles__ticker-animate___sCBhC`,
	"ticker": `styles__ticker___GW3jU`
};
export default ___CSS_LOADER_EXPORT___;
