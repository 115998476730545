// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.record-button {
  position: relative;
  min-width: 46px;
  height: 46px;
  transition: all 0.2s;
  border: 6px solid rgba(100, 104, 107, 0.7);
  border-radius: 50%;
  cursor: pointer;
  background: transparent;
}
.record-button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 28px;
  height: 28px;
  display: block;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  background-color: #f03;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.record-button.__active:before {
  transform: scaleX(0.65) scaleY(0.65) translate3d(-21px, -21px, 0);
  border-radius: 5px;
}
.record-button.__disabled {
  pointer-events: none;
  opacity: 0.6;
}
.record-button.__disabled:before {
  background-color: #bbb;
}
.record-button:not(.__active):hover:before {
  transform: scaleX(1.17) scaleY(1.17) translate3d(-12px, -12px, 0);
}`, "",{"version":3,"sources":["webpack://./app/components/auth/poh/RecordButton/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EAEA,eAAA;EACA,YAAA;EAEA,oBAAA;EACA,0CAAA;EACA,kBAAA;EACA,eAAA;EAEA,uBAAA;AAFF;AAIE;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,qCAAA;EAEA,WAAA;EACA,YAAA;EACA,cAAA;EAEA,kBAAA;EACA,sEAAA;EAEA,sBAAA;EACA,0CAAA;AALJ;AASI;EACE,iEAAA;EACA,kBAAA;AAPN;AAWE;EACE,oBAAA;EACA,YAAA;AATJ;AAWI;EACE,sBAAA;AATN;AAcI;EACE,iEAAA;AAZN","sourcesContent":[".record-button {\n  position: relative;\n\n  min-width: 46px;\n  height: 46px;\n\n  transition: all 0.2s;\n  border: 6px solid rgba(100, 104, 107, 0.7);\n  border-radius: 50%;\n  cursor: pointer;\n\n  background: transparent;\n\n  &:before {\n    content: \"\";\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate3d(-50%, -50%, 0);\n\n    width: 28px;\n    height: 28px;\n    display: block;\n\n    border-radius: 50%;\n    transition: transform 0.2s ease-in-out, border-radius 0.2s ease-in-out;\n\n    background-color: #f03;\n    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);\n  }\n\n  &.__active {\n    &:before {\n      transform: scaleX(0.65) scaleY(0.65) translate3d(-21px, -21px, 0);\n      border-radius: 5px;\n    }\n  }\n\n  &.__disabled {\n    pointer-events: none;\n    opacity: 0.6;\n\n    &:before {\n      background-color: #bbb;\n    }\n  }\n\n  &:not(.__active):hover {\n    &:before {\n      transform: scaleX(1.17) scaleY(1.17) translate3d(-12px, -12px, 0);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
