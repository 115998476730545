// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__layout-decor___vxnvy {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.styles__layout-content___AKfeA {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/IntroductorySection/AnimLayout/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EAEA,WAAA;EACA,YAAA;AADJ;AAIE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAFJ","sourcesContent":[".layout {\n  &-decor {\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    \n    width: 100%;\n    height: 100%;\n  }\n\n  &-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout-decor": `styles__layout-decor___vxnvy`,
	"layout-content": `styles__layout-content___AKfeA`
};
export default ___CSS_LOADER_EXPORT___;
