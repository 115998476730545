// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__social-icons___rDtAX {
  display: flex;
  gap: 0.5rem;
}
@media (min-width: 1280px) {
  .styles__social-icons___rDtAX {
    gap: 0.55vw;
  }
}
.styles__social-link___WQe3a {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
.styles__social-link___WQe3a svg {
  width: 100%;
  height: 100%;
}
@media (min-width: 1280px) {
  .styles__social-link___WQe3a {
    padding: 0.5vw;
    width: 2.7vw;
    height: 2.7vw;
    border-width: 0.1vw;
    border-radius: 0.6vw;
  }
}
.styles__social-link___WQe3a:hover {
  background-color: rgba(255, 111, 62, 0.1);
  border-color: #FF6F3E;
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/Footer/SocialIcons/styles.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,WAAA;AAAJ;AAEI;EAJF;IAKI,WAAA;EACJ;AACF;AAEE;EACE,eAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,yBAAA;EACA,qBAAA;EACA,8DAAA;AAFJ;AAII;EACE,WAAA;EACA,YAAA;AAFN;AAKI;EAhBF;IAiBI,cAAA;IACA,YAAA;IACA,aAAA;IACA,mBAAA;IACA,oBAAA;EAFJ;AACF;AAII;EACE,yCAAA;EACA,qBAAA;AAFN","sourcesContent":[".social {\n  &-icons {\n    display: flex;\n    gap: 0.5rem;\n  \n    @media (min-width: 1280px) {\n      gap: 0.55vw;\n    }\n  }\n\n  &-link {\n    padding: 0.5rem;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    border: 1px solid #e5e5e5;\n    border-radius: 0.5rem;\n    transition: background-color 0.3s ease, border-color 0.3s ease;\n\n    svg {\n      width: 100%;\n      height: 100%;\n    }\n\n    @media (min-width: 1280px) {\n      padding: 0.5vw;\n      width: 2.7vw;\n      height: 2.7vw;\n      border-width: 0.1vw;\n      border-radius: 0.6vw;\n    }\n\n    &:hover {\n      background-color: rgba(255, 111, 62, 0.1);\n      border-color: #FF6F3E;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"social-icons": `styles__social-icons___rDtAX`,
	"social-link": `styles__social-link___WQe3a`
};
export default ___CSS_LOADER_EXPORT___;
