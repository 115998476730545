// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__introductory___PN1HZ {
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
@media (min-width: 1440px) {
  .styles__introductory___PN1HZ {
    padding: 1.38vw;
  }
}
.styles__introductory-container___Vzc35 {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  gap: 20px;
}
@media (min-width: 1024px) {
  .styles__introductory-container___Vzc35 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
.styles__introductory-content___okPUf {
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/IntroductorySection/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EAEA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;AAAF;AAEE;EAXF;IAYI,eAAA;EACF;AACF;AACE;EACE,kBAAA;EACA,WAAA;EAEA,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAEI;EAVF;IAWI,mBAAA;IACA,sBAAA;EACJ;AACF;AAEE;EACE,iBAAA;EACA,oBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".introductory {\n  position: relative;\n\n  padding: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  min-height: 100vh;\n  overflow: hidden;\n\n  @media (min-width: 1440px) {\n    padding: 1.38vw;\n  }\n \n  &-container {\n    position: relative;\n    z-index: 10;\n\n    display: flex;\n    align-items: center;\n    justify-items: center;\n    flex-direction: column;\n    gap: 20px;\n\n    @media (min-width: 1024px) {\n      padding-top: 1.5rem;\n      padding-bottom: 1.5rem;\n    }\n  }\n\n  &-content {\n    padding-top: 2rem;\n    padding-bottom: 2rem;\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"introductory": `styles__introductory___PN1HZ`,
	"introductory-container": `styles__introductory-container___Vzc35`,
	"introductory-content": `styles__introductory-content___okPUf`
};
export default ___CSS_LOADER_EXPORT___;
