// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__projects-section___OPp67 {
  padding: 80px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media (min-width: 780px) {
  .styles__projects-section___OPp67 {
    padding: 120px 20px;
    gap: 40px;
  }
}
@media (min-width: 1440px) {
  .styles__projects-section___OPp67 {
    padding: 5.55vw 1.38vw;
    gap: 2.7vw;
  }
}

.styles__list___Ux12z {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
@media (min-width: 1440px) {
  .styles__list___Ux12z {
    gap: 0.69vw;
  }
}
.styles__list-item___KXTIh {
  flex-basis: 100%;
}
@media (min-width: 780px) {
  .styles__list-item___KXTIh {
    flex-basis: calc(50% - 5px);
  }
  .styles__list-item___KXTIh.styles___large___AxRNM {
    flex-basis: 100%;
  }
}
@media (min-width: 1440px) {
  .styles__list-item___KXTIh {
    flex-basis: calc(50% - 0.3472vw);
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/ProjectsSection/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AAAJ;AAEI;EARF;IASI,mBAAA;IACA,SAAA;EACJ;AACF;AACI;EAbF;IAcI,sBAAA;IACA,UAAA;EAEJ;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,SAAA;AACF;AACE;EALF;IAMI,WAAA;EAEF;AACF;AAAE;EACE,gBAAA;AAEJ;AAAI;EAHF;IAII,2BAAA;EAGJ;EADI;IACE,gBAAA;EAGN;AACF;AAAI;EAXF;IAYI,gCAAA;EAGJ;AACF","sourcesContent":[".projects {\n  &-section {\n    padding: 80px 16px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 20px;\n\n    @media (min-width: 780px) {\n      padding: 120px 20px;\n      gap: 40px;\n    }\n\n    @media (min-width: 1440px) {\n      padding: 5.55vw 1.38vw;\n      gap: 2.7vw;\n    }\n  }\n}\n\n.list {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n\n  @media (min-width: 1440px) {\n    gap: 0.69vw;\n  }\n\n  &-item {\n    flex-basis: 100%;\n\n    @media (min-width: 780px) {\n      flex-basis: calc(50% - 5px);\n      \n      &._large {\n        flex-basis: 100%;\n      }\n    }\n\n    @media (min-width: 1440px) {\n      flex-basis: calc(50% - 0.3472vw);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projects-section": `styles__projects-section___OPp67`,
	"list": `styles__list___Ux12z`,
	"list-item": `styles__list-item___KXTIh`,
	"_large": `styles___large___AxRNM`
};
export default ___CSS_LOADER_EXPORT___;
