import React from 'react';

export const LinearCircleSvg = () => (
  <svg width="930" height="930" viewBox="0 0 930 930" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
    <path d="M702.737 384.814C702.737 505.905 604.573 604.069 483.482 604.069C362.391 604.069 264.228 505.905 264.228 384.814C264.228 382.243 265.508 379.376 268.087 376.306C270.662 373.241 274.493 370.019 279.497 366.746C289.503 360.203 304.131 353.504 322.571 347.481C359.446 335.437 411.494 326.118 472.13 326.118C532.756 326.118 590.483 337.258 633.05 350.221C654.334 356.702 671.815 363.636 683.966 369.852C690.044 372.961 694.77 375.883 697.97 378.469C699.571 379.762 700.774 380.959 701.573 382.041C702.376 383.129 702.737 384.054 702.737 384.814Z" stroke="white"/>
    <path d="M908.224 384.814C908.224 505.905 810.06 604.069 688.969 604.069C567.878 604.069 469.714 505.905 469.714 384.814C469.714 382.243 470.995 379.376 473.574 376.306C476.149 373.241 479.979 370.019 484.984 366.746C494.99 360.203 509.618 353.504 528.058 347.481C564.933 335.437 616.981 326.118 677.616 326.118C738.243 326.118 795.97 337.258 838.537 350.221C859.821 356.702 877.302 363.636 889.453 369.852C895.53 372.961 900.257 375.883 903.457 378.469C905.057 379.762 906.26 380.959 907.059 382.041C907.863 383.129 908.224 384.054 908.224 384.814Z" stroke="white"/>
    <path d="M460.285 384.814C460.285 505.905 362.122 604.069 241.031 604.069C119.94 604.069 21.7759 505.905 21.7759 384.814C21.7759 382.243 23.0565 379.376 25.6356 376.306C28.2101 373.241 32.041 370.019 37.0451 366.746C47.0516 360.203 61.6793 353.504 80.1194 347.481C116.995 335.437 169.042 326.118 229.678 326.118C290.304 326.118 348.032 337.258 390.599 350.221C411.883 356.702 429.364 363.636 441.514 369.852C447.592 372.961 452.319 375.883 455.519 378.469C457.119 379.762 458.322 380.959 459.121 382.041C459.925 383.129 460.285 384.054 460.285 384.814Z" stroke="white"/>
    <path d="M707.155 399.333C699.835 520.202 595.916 612.252 475.046 604.931C354.177 597.611 262.127 493.692 269.448 372.823C269.603 370.256 271.055 367.472 273.815 364.563C276.57 361.66 280.589 358.675 285.781 355.711C296.165 349.784 311.171 343.982 329.942 339.085C367.477 329.292 419.993 323.137 480.518 326.803C541.034 330.468 597.982 345.077 639.687 360.59C660.541 368.346 677.57 376.323 689.323 383.263C695.202 386.734 699.743 389.936 702.781 392.711C704.3 394.098 705.429 395.366 706.161 396.494C706.897 397.629 707.201 398.574 707.155 399.333Z" stroke="white"/>
    <path d="M912.266 411.756C904.945 532.625 801.027 624.675 680.157 617.354C559.288 610.034 467.238 506.115 474.559 385.246C474.714 382.679 476.166 379.895 478.926 376.987C481.681 374.083 485.699 371.098 490.892 368.134C501.276 362.208 516.282 356.406 535.052 351.508C572.588 341.715 625.104 335.56 685.629 339.226C746.144 342.891 803.093 357.501 844.798 373.013C865.651 380.769 882.681 388.747 894.434 395.686C900.312 399.158 904.854 402.359 907.892 405.134C909.411 406.522 910.539 407.789 911.271 408.917C912.008 410.052 912.312 410.997 912.266 411.756Z" stroke="white"/>
    <path d="M465.147 384.676C457.826 505.546 353.908 597.596 233.038 590.275C112.169 582.955 20.1189 479.036 27.4395 358.166C27.5949 355.6 29.0465 352.816 31.8064 349.907C34.5616 347.004 38.5802 344.019 43.773 341.055C54.1568 335.128 69.1627 329.326 87.9333 324.429C125.469 314.636 177.985 308.481 238.51 312.147C299.025 315.812 355.974 330.421 397.679 345.934C418.532 353.69 435.562 361.667 447.315 368.607C453.193 372.078 457.735 375.28 460.772 378.054C462.292 379.442 463.42 380.71 464.152 381.838C464.889 382.973 465.193 383.918 465.147 384.676Z" stroke="white"/>
    <path d="M711.586 418.626C694.76 538.542 583.909 622.113 463.992 605.287C344.076 588.461 260.505 477.609 277.331 357.693C277.688 355.147 279.355 352.486 282.336 349.804C285.311 347.126 289.553 344.468 294.963 341.922C305.782 336.833 321.198 332.232 340.296 328.829C378.487 322.026 431.325 320.03 491.372 328.455C551.411 336.88 607.03 355.933 647.383 374.685C667.56 384.061 683.908 393.356 695.077 401.201C700.663 405.124 704.938 408.674 707.748 411.68C709.153 413.183 710.178 414.536 710.819 415.718C711.463 416.907 711.692 417.873 711.586 418.626Z" stroke="white"/>
    <path d="M915.079 447.179C898.253 567.095 787.401 650.666 667.485 633.84C547.568 617.014 463.997 506.162 480.823 386.246C481.181 383.7 482.847 381.039 485.828 378.357C488.803 375.68 493.045 373.021 498.455 370.476C509.274 365.386 524.69 360.785 543.788 357.382C581.98 350.579 634.817 348.583 694.865 357.009C754.903 365.433 810.522 384.486 850.875 403.238C871.052 412.614 887.4 421.909 898.569 429.754C904.155 433.678 908.43 437.227 911.24 440.233C912.645 441.736 913.67 443.089 914.311 444.271C914.956 445.46 915.184 446.427 915.079 447.179Z" stroke="white"/>
    <path d="M471.486 384.935C454.66 504.851 343.808 588.422 223.892 571.596C103.976 554.77 20.4044 443.918 37.2306 324.002C37.5879 321.455 39.2544 318.795 42.235 316.113C45.2105 313.435 49.4519 310.777 54.8622 308.231C65.6809 303.142 81.0975 298.54 100.196 295.138C138.387 288.335 191.224 286.339 251.272 294.764C311.31 303.189 366.93 322.242 407.282 340.994C427.459 350.37 443.807 359.665 454.976 367.509C460.563 371.433 464.838 374.983 467.647 377.988C469.052 379.492 470.077 380.844 470.718 382.027C471.363 383.216 471.591 384.182 471.486 384.935Z" stroke="white"/>
    <path d="M714.379 437.265C688.599 555.58 571.787 630.595 453.472 604.815C335.157 579.035 260.142 462.223 285.922 343.908C286.47 341.396 288.331 338.868 291.505 336.417C294.673 333.971 299.102 331.638 304.688 329.506C315.858 325.242 331.577 321.811 350.876 319.852C389.47 315.935 442.309 317.91 501.554 330.819C560.791 343.727 614.823 366.901 653.654 388.629C673.071 399.493 688.675 409.989 699.223 418.65C704.5 422.982 708.496 426.843 711.072 430.05C712.361 431.655 713.281 433.08 713.832 434.308C714.385 435.542 714.54 436.523 714.379 437.265Z" stroke="white"/>
    <path d="M915.154 481.009C889.374 599.324 772.563 674.338 654.248 648.558C535.933 622.779 460.918 505.967 486.698 387.652C487.245 385.14 489.106 382.611 492.28 380.161C495.448 377.714 499.877 375.381 505.463 373.249C516.633 368.986 532.352 365.555 551.652 363.596C590.246 359.678 643.084 361.654 702.33 374.563C761.566 387.47 815.598 410.645 854.43 432.372C873.846 443.237 889.45 453.732 899.999 462.393C905.275 466.725 909.272 470.586 911.848 473.794C913.136 475.398 914.057 476.824 914.607 478.051C915.16 479.285 915.316 480.266 915.154 481.009Z" stroke="white"/>
    <path d="M477.485 385.647C451.705 503.962 334.893 578.977 216.578 553.197C98.2631 527.417 23.2484 410.605 49.0281 292.29C49.5755 289.778 51.437 287.25 54.6106 284.799C57.7786 282.353 62.2077 280.02 67.7938 277.888C78.964 273.624 94.6825 270.193 113.982 268.234C152.576 264.317 205.415 266.292 264.66 279.201C323.897 292.109 377.929 315.283 416.76 337.011C436.177 347.875 451.781 358.371 462.329 367.032C467.606 371.364 471.602 375.225 474.178 378.432C475.467 380.037 476.387 381.462 476.937 382.689C477.491 383.924 477.646 384.905 477.485 385.647Z" stroke="white"/>
    <path d="M715.676 453.898C682.093 570.239 560.555 637.327 444.214 603.744C327.873 570.16 260.785 448.623 294.369 332.282C295.082 329.811 297.107 327.413 300.437 325.178C303.76 322.947 308.334 320.914 314.05 319.158C325.479 315.646 341.39 313.267 360.777 312.594C399.547 311.249 452.137 316.731 510.394 333.548C568.642 350.362 621.015 377.076 658.317 401.335C676.969 413.465 691.841 424.975 701.791 434.317C706.768 438.99 710.499 443.108 712.856 446.48C714.035 448.166 714.859 449.65 715.327 450.911C715.797 452.179 715.887 453.168 715.676 453.898Z" stroke="white"/>
    <path d="M913.102 510.889C879.518 627.229 757.981 694.318 641.64 660.734C525.299 627.151 458.211 505.613 491.794 389.272C492.508 386.802 494.533 384.403 497.862 382.168C501.186 379.938 505.76 377.904 511.476 376.148C522.904 372.637 538.816 370.257 558.203 369.585C596.972 368.24 649.563 373.722 707.82 390.539C766.068 407.353 818.441 434.066 855.743 458.326C874.395 470.456 889.267 481.965 899.217 491.308C904.194 495.981 907.925 500.098 910.282 503.47C911.461 505.157 912.285 506.64 912.752 507.901C913.223 509.17 913.313 510.159 913.102 510.889Z" stroke="white"/>
    <path d="M482.735 386.656C449.152 502.997 327.614 570.085 211.273 536.501C94.9325 502.918 27.8443 381.38 61.4277 265.04C62.1409 262.569 64.1662 260.17 67.4956 257.936C70.8192 255.705 75.3934 253.672 81.1088 251.915C92.5376 248.404 108.449 246.025 127.837 245.352C166.606 244.007 219.196 249.489 277.453 266.306C335.701 283.12 388.074 309.833 425.376 334.093C444.028 346.223 458.9 357.732 468.85 367.075C473.827 371.748 477.558 375.866 479.915 379.237C481.094 380.924 481.918 382.408 482.386 383.669C482.856 384.937 482.946 385.926 482.735 386.656Z" stroke="white"/>
    <path d="M715.785 473.512C673.215 586.874 546.808 644.262 433.446 601.692C320.085 559.122 262.696 432.715 305.266 319.354C306.17 316.946 308.377 314.713 311.87 312.746C315.358 310.781 320.077 309.112 325.912 307.807C337.581 305.199 353.629 304.07 373.01 304.914C411.766 306.602 463.767 316.176 520.532 337.493C577.289 358.806 627.415 389.529 662.708 416.629C680.355 430.179 694.282 442.815 703.472 452.906C708.069 457.954 711.467 462.35 713.553 465.896C714.597 467.669 715.302 469.213 715.67 470.507C716.04 471.808 716.052 472.801 715.785 473.512Z" stroke="white"/>
    <path d="M908.154 545.753C865.585 659.115 739.177 716.503 625.816 673.933C512.454 631.363 455.066 504.956 497.636 391.595C498.54 389.187 500.746 386.954 504.24 384.986C507.728 383.022 512.447 381.352 518.282 380.048C529.95 377.44 545.999 376.311 565.38 377.155C604.135 378.843 656.137 388.417 712.902 409.734C769.658 431.047 819.785 461.77 855.077 488.87C872.724 502.42 886.652 515.056 895.842 525.147C900.438 530.195 903.836 534.591 905.923 538.137C906.966 539.91 907.672 541.454 908.039 542.748C908.409 544.049 908.422 545.042 908.154 545.753Z" stroke="white"/>
    <path d="M488.809 388.28C446.239 501.641 319.832 559.029 206.47 516.46C93.1085 473.89 35.7203 347.483 78.29 234.121C79.194 231.714 81.4006 229.481 84.8943 227.513C88.382 225.549 93.1012 223.879 98.9363 222.575C110.604 219.967 126.653 218.838 146.034 219.682C184.79 221.37 236.791 230.944 293.556 252.26C350.313 273.573 400.439 304.297 435.732 331.396C453.379 344.946 467.306 357.583 476.496 367.674C481.092 372.721 484.491 377.118 486.577 380.664C487.621 382.437 488.326 383.981 488.694 385.274C489.064 386.576 489.076 387.568 488.809 388.28Z" stroke="white"/>
    <path d="M714.452 492.248C663.522 602.108 533.176 649.88 423.316 598.95C313.456 548.019 265.684 417.674 316.614 307.814C317.696 305.481 320.063 303.419 323.694 301.718C327.319 300.02 332.15 298.708 338.066 297.844C349.897 296.116 365.985 296.191 385.249 298.482C423.769 303.065 474.909 316.501 529.92 342.004C584.924 367.503 632.612 401.89 665.778 431.553C682.362 446.386 695.306 460.028 703.715 470.779C707.921 476.156 710.981 480.794 712.796 484.486C713.704 486.333 714.292 487.925 714.562 489.242C714.833 490.568 714.771 491.559 714.452 492.248Z" stroke="white"/>
    <path d="M900.88 578.675C849.95 688.535 719.604 736.307 609.744 685.377C499.884 634.447 452.112 504.101 503.043 394.241C504.124 391.908 506.492 389.846 510.123 388.145C513.748 386.448 518.578 385.135 524.495 384.271C536.325 382.543 552.414 382.618 571.677 384.909C610.198 389.492 661.337 402.928 716.349 428.431C771.352 453.93 819.04 488.317 852.206 517.981C868.79 532.813 881.734 546.455 890.143 557.206C894.349 562.583 897.409 567.222 899.224 570.913C900.132 572.76 900.72 574.352 900.99 575.669C901.261 576.995 901.199 577.986 900.88 578.675Z" stroke="white"/>
    <path d="M494.487 390.274C443.557 500.134 313.211 547.905 203.352 496.975C93.4919 446.045 45.7199 315.699 96.65 205.84C97.7315 203.507 100.099 201.445 103.73 199.744C107.355 198.046 112.186 196.734 118.102 195.87C129.933 194.142 146.021 194.217 165.284 196.508C203.805 201.09 254.945 214.527 309.956 240.03C364.959 265.529 412.647 299.916 445.814 329.579C462.398 344.412 475.341 358.054 483.75 368.805C487.956 374.182 491.016 378.82 492.832 382.512C493.74 384.359 494.328 385.951 494.597 387.268C494.869 388.593 494.807 389.584 494.487 390.274Z" stroke="white"/>
    <path d="M712.233 507.982C654.452 614.398 521.344 653.825 414.928 596.044C308.512 538.263 269.085 405.155 326.866 298.739C328.093 296.479 330.586 294.571 334.317 293.104C338.043 291.639 342.947 290.635 348.906 290.147C360.822 289.171 376.874 290.264 395.953 293.77C434.107 300.781 484.293 317.427 537.58 346.361C590.86 375.29 636.275 412.626 667.498 444.329C683.11 460.181 695.164 474.615 702.876 485.876C706.733 491.509 709.493 496.332 711.072 500.131C711.861 502.031 712.347 503.658 712.533 504.99C712.72 506.329 712.595 507.314 712.233 507.982Z" stroke="white"/>
    <path d="M892.817 606.032C835.036 712.449 701.928 751.875 595.512 694.094C489.096 636.313 449.669 503.205 507.45 396.789C508.677 394.529 511.17 392.621 514.902 391.154C518.627 389.689 523.531 388.685 529.49 388.197C541.407 387.221 557.458 388.314 576.537 391.82C614.691 398.831 664.878 415.478 718.165 444.411C771.444 473.34 816.86 510.676 848.083 542.379C863.695 558.231 875.749 572.666 883.461 583.927C887.318 589.559 890.078 594.382 891.656 598.182C892.445 600.082 892.931 601.708 893.117 603.04C893.304 604.38 893.18 605.365 892.817 606.032Z" stroke="white"/>
    <path d="M499.163 392.29C441.382 498.707 308.274 538.133 201.858 480.352C95.4417 422.571 56.015 289.463 113.796 183.047C115.023 180.787 117.516 178.879 121.248 177.412C124.973 175.947 129.877 174.943 135.836 174.455C147.752 173.479 163.804 174.572 182.883 178.078C221.037 185.089 271.223 201.736 324.511 230.669C377.79 259.598 423.206 296.934 454.428 328.637C470.04 344.489 482.094 358.924 489.806 370.185C493.664 375.817 496.424 380.64 498.002 384.439C498.791 386.34 499.277 387.966 499.463 389.298C499.65 390.638 499.526 391.623 499.163 392.29Z" stroke="white"/>
    <path d="M708.808 524.433C644.035 626.743 508.586 657.174 406.276 592.401C303.965 527.628 273.535 392.179 338.308 289.869C339.683 287.696 342.298 285.959 346.12 284.745C349.934 283.532 354.895 282.859 360.873 282.771C372.828 282.595 388.77 284.759 407.572 289.534C445.171 299.083 494.131 319.051 545.363 351.485C596.587 383.915 639.402 424.207 668.433 457.928C682.949 474.79 694.01 489.998 700.951 501.75C704.423 507.629 706.854 512.625 708.174 516.522C708.835 518.47 709.211 520.125 709.307 521.467C709.404 522.816 709.214 523.791 708.808 524.433Z" stroke="white"/>
    <path d="M882.425 634.349C817.652 736.66 682.204 767.09 579.893 702.317C477.582 637.544 447.152 502.096 511.925 399.785C513.3 397.613 515.916 395.876 519.737 394.661C523.552 393.449 528.512 392.776 534.49 392.688C546.445 392.511 562.388 394.676 581.19 399.451C618.789 409 667.749 428.967 718.98 461.402C770.204 493.832 813.019 534.123 842.05 567.845C856.567 584.706 867.628 599.915 874.568 611.667C878.04 617.545 880.471 622.542 881.792 626.438C882.452 628.387 882.828 630.042 882.925 631.383C883.021 632.733 882.831 633.707 882.425 634.349Z" stroke="white"/>
    <path d="M503.959 394.743C439.185 497.054 303.737 527.484 201.427 462.711C99.1158 397.938 68.6855 262.49 133.459 160.179C134.834 158.007 137.449 156.27 141.271 155.055C145.085 153.843 150.046 153.169 156.024 153.081C167.979 152.905 183.921 155.07 202.723 159.845C240.322 169.393 289.282 189.361 340.514 221.796C391.738 254.226 434.553 294.517 463.584 328.239C478.1 345.1 489.161 360.309 496.102 372.061C499.574 377.939 502.005 382.936 503.325 386.832C503.986 388.781 504.362 390.436 504.458 391.777C504.555 393.127 504.365 394.101 503.959 394.743Z" stroke="white"/>
    <path d="M704.528 539.854C633.364 637.827 496.251 659.559 398.278 588.394C300.306 517.229 278.574 380.116 349.739 282.144C351.25 280.063 353.971 278.497 357.862 277.528C361.746 276.562 366.739 276.206 372.711 276.499C384.653 277.086 400.425 280.262 418.884 286.226C455.798 298.153 503.385 321.202 552.444 356.837C601.496 392.467 641.655 435.407 668.477 470.911C681.888 488.664 691.957 504.547 698.134 516.717C701.224 522.805 703.332 527.946 704.401 531.919C704.936 533.906 705.206 535.582 705.216 536.926C705.227 538.279 704.975 539.24 704.528 539.854Z" stroke="white"/>
    <path d="M870.784 660.622C799.619 758.594 662.507 780.326 564.534 709.161C466.562 637.996 444.83 500.883 515.995 402.911C517.506 400.83 520.227 399.264 524.118 398.296C528.002 397.329 532.995 396.973 538.967 397.266C550.909 397.853 566.681 401.03 585.14 406.994C622.053 418.92 669.641 441.969 718.7 477.605C767.751 513.235 807.911 556.174 834.733 591.678C848.144 609.431 858.213 625.314 864.39 637.484C867.48 643.572 869.588 648.714 870.657 652.686C871.192 654.673 871.461 656.349 871.472 657.694C871.483 659.046 871.231 660.007 870.784 660.622Z" stroke="white"/>
    <path d="M508.365 397.367C437.201 495.34 300.088 517.072 202.115 445.907C104.143 374.742 82.4109 237.629 153.576 139.657C155.087 137.576 157.808 136.01 161.699 135.041C165.583 134.075 170.576 133.719 176.548 134.012C188.49 134.599 204.262 137.775 222.721 143.74C259.634 155.666 307.222 178.715 356.281 214.35C405.332 249.98 445.492 292.92 472.314 328.424C485.725 346.177 495.794 362.06 501.971 374.23C505.061 380.318 507.169 385.459 508.238 389.432C508.773 391.419 509.043 393.095 509.053 394.439C509.064 395.792 508.812 396.753 508.365 397.367Z" stroke="white"/>
    <path d="M699.547 554.271C622.562 647.74 484.383 661.103 390.914 584.119C297.445 507.135 284.081 368.955 361.065 275.486C362.7 273.501 365.511 272.103 369.454 271.372C373.389 270.643 378.395 270.592 384.338 271.247C396.222 272.558 411.772 276.687 429.835 283.761C465.956 297.908 512.055 323.804 558.859 362.354C605.656 400.897 643.133 446.197 667.749 483.265C680.057 501.799 689.143 518.264 694.57 530.788C697.284 537.052 699.075 542.312 699.901 546.342C700.314 548.358 700.482 550.047 700.411 551.39C700.339 552.741 700.03 553.684 699.547 554.271Z" stroke="white"/>
    <path d="M858.158 684.908C781.174 778.377 642.994 791.741 549.525 714.756C456.056 637.772 442.693 499.592 519.677 406.123C521.312 404.139 524.123 402.74 528.065 402.01C532.001 401.281 537.007 401.229 542.95 401.885C554.834 403.195 570.384 407.324 588.447 414.399C624.568 428.545 670.667 454.442 717.471 492.991C764.268 531.535 801.745 576.835 826.361 613.902C838.669 632.437 847.755 648.902 853.181 661.425C855.896 667.689 857.687 672.949 858.513 676.98C858.926 678.995 859.094 680.684 859.023 682.027C858.951 683.378 858.641 684.322 858.158 684.908Z" stroke="white"/>
    <path d="M512.4 400.13C435.415 493.599 297.236 506.962 203.767 429.978C110.298 352.994 96.9341 214.814 173.918 121.345C175.553 119.36 178.364 117.962 182.307 117.231C186.242 116.502 191.248 116.451 197.191 117.106C209.075 118.417 224.625 122.546 242.688 129.62C278.809 143.767 324.908 169.664 371.712 208.213C418.509 246.756 455.986 292.056 480.602 329.124C492.91 347.658 501.996 364.123 507.423 376.647C510.137 382.911 511.928 388.171 512.754 392.201C513.167 394.217 513.335 395.906 513.264 397.249C513.192 398.6 512.883 399.543 512.4 400.13Z" stroke="white"/>
    <path d="M693.807 568.103C611.384 656.813 472.654 661.91 383.943 579.488C295.233 497.065 290.135 358.334 372.558 269.624C374.308 267.74 377.197 266.512 381.177 266.018C385.149 265.525 390.149 265.772 396.042 266.781C407.827 268.798 423.103 273.847 440.711 281.987C475.924 298.263 520.397 326.863 564.818 368.136C609.232 409.402 643.941 456.856 666.301 495.326C677.482 514.562 685.57 531.54 690.24 544.365C692.576 550.78 694.05 556.137 694.634 560.209C694.926 562.246 694.993 563.942 694.842 565.279C694.69 566.623 694.324 567.546 693.807 568.103Z" stroke="white"/>
    <path d="M844.345 707.968C761.922 796.679 623.191 801.776 534.481 719.353C445.77 636.931 440.673 498.2 523.096 409.49C524.846 407.606 527.735 406.378 531.714 405.884C535.687 405.391 540.686 405.638 546.58 406.647C558.364 408.664 573.64 413.713 591.249 421.852C626.462 438.128 670.934 466.729 715.356 508.001C759.77 549.268 794.478 596.722 816.839 635.192C828.02 654.428 836.107 671.406 840.777 684.23C843.113 690.645 844.587 696.003 845.172 700.075C845.464 702.112 845.53 703.808 845.379 705.144C845.227 706.489 844.862 707.412 844.345 707.968Z" stroke="white"/>
    <path d="M516.188 403.072C433.766 491.782 295.035 496.88 206.325 414.457C117.614 332.035 112.517 193.304 194.939 104.593C196.69 102.71 199.579 101.481 203.558 100.988C207.53 100.495 212.53 100.742 218.423 101.75C230.208 103.768 245.484 108.817 263.093 116.956C298.306 133.232 342.778 161.832 387.199 203.105C431.614 244.371 466.322 291.826 488.683 330.296C499.864 349.532 507.951 366.509 512.621 379.334C514.957 385.749 516.431 391.107 517.015 395.179C517.308 397.216 517.374 398.912 517.223 400.248C517.071 401.592 516.705 402.515 516.188 403.072Z" stroke="white"/>
    <path d="M686.899 582.241C599.077 665.609 460.3 661.999 376.931 574.177C293.563 486.355 297.173 347.578 384.995 264.209C386.86 262.439 389.82 261.394 393.823 261.151C397.818 260.908 402.793 261.468 408.611 262.844C420.246 265.596 435.175 271.593 452.239 280.82C486.362 299.272 528.954 330.604 570.701 374.581C612.441 418.55 644.105 468.087 664.01 507.883C673.963 527.782 680.97 545.234 684.827 558.326C686.756 564.875 687.892 570.314 688.219 574.415C688.383 576.466 688.343 578.163 688.109 579.487C687.873 580.819 687.45 581.718 686.899 582.241Z" stroke="white"/>
    <path d="M828.372 731.272C740.55 814.641 601.773 811.03 518.404 723.208C435.036 635.386 438.646 496.609 526.468 413.24C528.333 411.47 531.294 410.425 535.296 410.182C539.291 409.939 544.266 410.499 550.084 411.875C561.719 414.628 576.649 420.624 593.712 429.851C627.836 448.303 670.428 479.636 712.174 523.612C753.914 567.582 785.578 617.119 805.484 656.915C815.437 676.814 822.443 694.265 826.3 707.357C828.229 713.906 829.365 719.346 829.693 723.446C829.856 725.498 829.817 727.194 829.582 728.519C829.346 729.851 828.923 730.749 828.372 731.272Z" stroke="white"/>
    <path d="M519.976 406.403C432.154 489.772 293.377 486.161 210.008 398.339C126.64 310.517 130.25 171.74 218.072 88.3714C219.937 86.6011 222.898 85.5564 226.9 85.313C230.895 85.0701 235.87 85.63 241.688 87.0064C253.323 89.7585 268.253 95.7553 285.317 104.982C319.44 123.434 362.032 154.767 403.778 198.743C445.518 242.713 477.182 292.25 497.088 332.046C507.041 351.945 514.047 369.396 517.904 382.488C519.833 389.037 520.969 394.477 521.297 398.577C521.46 400.629 521.421 402.325 521.186 403.65C520.95 404.982 520.527 405.88 519.976 406.403Z" stroke="white"/>
    <path d="M680.289 593.993C588.116 672.524 449.733 661.466 371.201 569.293C292.669 477.12 303.728 338.737 395.9 260.206C397.858 258.538 400.87 257.654 404.88 257.626C408.882 257.598 413.82 258.424 419.556 260.111C431.026 263.484 445.612 270.274 462.156 280.404C495.238 300.662 536.086 334.236 575.41 380.391C614.729 426.539 643.687 477.705 661.426 518.513C670.296 538.918 676.356 556.721 679.504 570.001C681.079 576.644 681.92 582.136 682.027 586.249C682.081 588.306 681.95 589.998 681.645 591.308C681.337 592.625 680.867 593.5 680.289 593.993Z" stroke="white"/>
    <path d="M813.554 750.405C721.382 828.937 582.998 817.879 504.467 725.706C425.935 633.533 436.993 495.15 529.166 416.618C531.123 414.951 534.136 414.067 538.145 414.039C542.148 414.011 547.085 414.837 552.821 416.524C564.292 419.897 578.878 426.687 595.421 436.817C628.504 457.075 669.352 490.649 708.676 536.804C747.994 582.952 776.953 634.118 794.692 674.926C803.562 695.331 809.621 713.133 812.77 726.414C814.344 733.057 815.186 738.549 815.293 742.662C815.346 744.719 815.215 746.411 814.91 747.721C814.603 749.038 814.133 749.913 813.554 750.405Z" stroke="white"/>
    <path d="M523.05 409.44C430.878 487.971 292.494 476.913 213.963 384.74C135.431 292.568 146.489 154.184 238.662 75.6528C240.619 73.9851 243.632 73.1009 247.642 73.0729C251.644 73.0449 256.581 73.8712 262.318 75.558C273.788 78.9311 288.374 85.721 304.917 95.8513C338 116.109 378.848 149.684 418.172 195.838C457.491 241.986 486.449 293.153 504.188 333.96C513.058 354.365 519.117 372.168 522.266 385.448C523.84 392.091 524.682 397.584 524.789 401.696C524.843 403.753 524.712 405.445 524.406 406.755C524.099 408.073 523.629 408.947 523.05 409.44Z" stroke="white"/>
    <path d="M671.021 608.338C573.72 680.418 436.409 659.971 364.33 562.67C292.25 465.369 312.696 328.058 409.998 255.979C412.064 254.448 415.129 253.771 419.132 254.016C423.127 254.26 427.996 255.42 433.605 257.493C444.819 261.639 458.909 269.405 474.725 280.637C506.353 303.099 544.823 339.374 580.916 388.097C617.004 436.813 642.415 489.83 657.337 531.75C664.798 552.711 669.633 570.885 671.87 584.349C672.99 591.083 673.456 596.62 673.283 600.731C673.196 602.786 672.95 604.466 672.557 605.752C672.161 607.045 671.632 607.886 671.021 608.338Z" stroke="white"/>
    <path d="M793.338 773.456C696.036 845.536 558.726 825.09 486.646 727.788C414.566 630.487 435.013 493.177 532.314 421.097C534.38 419.566 537.446 418.889 541.448 419.134C545.443 419.378 550.313 420.538 555.921 422.612C567.135 426.757 581.225 434.523 597.042 445.756C628.67 468.217 667.139 504.492 703.233 553.215C739.321 601.931 764.731 654.949 779.654 696.869C787.115 717.829 791.949 736.003 794.187 749.467C795.306 756.201 795.772 761.739 795.599 765.849C795.512 767.905 795.267 769.584 794.873 770.87C794.477 772.164 793.948 773.004 793.338 773.456Z" stroke="white"/>
    <path d="M526.701 413.52C429.4 485.599 292.089 465.153 220.009 367.852C147.93 270.551 168.376 133.24 265.677 61.1604C267.743 59.6298 270.809 58.9525 274.811 59.1973C278.807 59.4417 283.676 60.6019 289.284 62.675C300.499 66.8205 314.589 74.5869 330.405 85.8191C362.033 108.28 400.502 144.556 436.596 193.279C472.684 241.995 498.095 295.012 513.017 336.932C520.478 357.893 525.313 376.066 527.55 389.53C528.669 396.265 529.135 401.802 528.962 405.912C528.876 407.968 528.63 409.648 528.236 410.933C527.84 412.227 527.311 413.067 526.701 413.52Z" stroke="white"/>
    <path d="M661.054 621.702C559.181 687.161 423.531 657.641 358.073 555.767C292.614 453.893 322.134 318.244 424.008 252.786C426.171 251.396 429.275 250.923 433.252 251.433C437.222 251.943 442.004 253.424 447.462 255.865C458.377 260.746 471.92 269.431 486.955 281.689C517.022 306.201 552.997 344.951 585.775 395.964C618.548 446.968 640.382 501.557 652.487 544.375C658.539 565.785 662.156 584.24 663.495 597.822C664.164 604.616 664.261 610.172 663.816 614.262C663.593 616.308 663.236 617.967 662.758 619.224C662.277 620.488 661.693 621.292 661.054 621.702Z" stroke="white"/>
    <path d="M772.134 794.576C670.261 860.035 534.611 830.514 469.153 728.641C403.694 626.767 433.214 491.118 535.088 425.659C537.251 424.269 540.355 423.797 544.332 424.307C548.303 424.816 553.084 426.297 558.542 428.738C569.457 433.62 583 442.305 598.035 454.562C628.102 479.075 664.077 517.825 696.855 568.837C729.628 619.842 751.462 674.43 763.567 717.249C769.62 738.659 773.236 757.113 774.575 770.696C775.244 777.49 775.341 783.046 774.896 787.136C774.673 789.181 774.316 790.841 773.838 792.098C773.357 793.362 772.773 794.165 772.134 794.576Z" stroke="white"/>
    <path d="M529.991 417.728C428.117 483.186 292.468 453.666 227.009 351.792C161.551 249.919 191.071 114.269 292.945 48.8107C295.108 47.4207 298.212 46.9486 302.189 47.4586C306.159 47.9678 310.941 49.4488 316.399 51.8898C327.313 56.771 340.856 65.456 355.892 77.7138C385.958 102.226 421.933 140.976 454.711 191.989C487.484 242.994 509.318 297.582 521.423 340.4C527.476 361.81 531.093 380.265 532.431 393.847C533.101 400.641 533.198 406.197 532.752 410.287C532.529 412.333 532.173 413.992 531.694 415.249C531.213 416.513 530.63 417.317 529.991 417.728Z" stroke="white"/>
    <path d="M648.704 636.02C542.191 693.622 409.15 653.972 351.547 547.459C293.945 440.946 333.595 307.904 440.108 250.302C442.37 249.079 445.501 248.841 449.428 249.65C453.349 250.456 458.005 252.293 463.264 255.138C473.78 260.827 486.631 270.507 500.701 283.862C528.836 310.569 561.792 351.918 590.636 405.254C619.475 458.581 637.137 514.658 645.983 558.267C650.407 580.072 652.624 598.746 652.936 612.391C653.092 619.216 652.771 624.764 652.018 628.808C651.642 630.831 651.161 632.459 650.59 633.676C650.015 634.901 649.372 635.658 648.704 636.02Z" stroke="white"/>
    <path d="M746.453 816.767C639.94 874.369 506.898 834.719 449.296 728.206C391.694 621.693 431.344 488.652 537.857 431.049C540.119 429.826 543.249 429.589 547.177 430.397C551.097 431.204 555.754 433.041 561.013 435.886C571.529 441.575 584.379 451.255 598.449 464.61C626.585 491.316 659.54 532.665 688.384 586.001C717.224 639.329 734.885 695.406 743.732 739.014C748.155 760.819 750.372 779.494 750.684 793.139C750.84 799.964 750.519 805.511 749.767 809.556C749.39 811.579 748.91 813.207 748.338 814.424C747.763 815.649 747.121 816.406 746.453 816.767Z" stroke="white"/>
    <path d="M533.372 422.757C426.859 480.359 293.817 440.709 236.215 334.196C178.613 227.683 218.263 94.641 324.776 37.0388C327.038 35.8156 330.168 35.5786 334.096 36.3866C338.016 37.1933 342.673 39.0301 347.932 41.8752C358.448 47.5643 371.298 57.2444 385.368 70.5994C413.504 97.3058 446.459 138.655 475.303 191.991C504.143 245.318 521.804 301.395 530.651 345.004C535.074 366.809 537.291 385.483 537.603 399.128C537.759 405.953 537.438 411.501 536.686 415.545C536.309 417.568 535.829 419.196 535.257 420.413C534.682 421.638 534.04 422.395 533.372 422.757Z" stroke="white"/>
    <path d="M635.667 649.042C525.215 698.674 395.441 649.368 345.81 538.916C296.178 428.463 345.484 298.69 455.936 249.058C458.282 248.004 461.421 247.998 465.279 249.092C469.13 250.184 473.639 252.357 478.675 255.581C488.745 262.026 500.851 272.623 513.903 286.974C540.003 315.673 569.835 359.329 594.688 414.637C619.537 469.937 633.036 527.159 638.659 571.299C641.471 593.37 642.311 612.157 641.621 625.788C641.276 632.606 640.549 638.115 639.501 642.094C638.978 644.083 638.379 645.672 637.72 646.844C637.056 648.023 636.36 648.731 635.667 649.042Z" stroke="white"/>
    <path d="M719.889 836.474C609.436 886.106 479.663 836.8 430.031 726.348C380.4 615.895 429.705 486.122 540.158 436.49C542.503 435.437 545.643 435.43 549.5 436.524C553.351 437.616 557.861 439.79 562.897 443.013C572.967 449.458 585.072 460.055 598.124 474.407C624.224 503.106 654.057 546.761 678.91 602.07C703.758 657.37 717.258 714.592 722.881 758.732C725.692 780.802 726.533 799.589 725.843 813.22C725.498 820.038 724.77 825.547 723.723 829.526C723.199 831.516 722.601 833.104 721.941 834.276C721.278 835.455 720.582 836.163 719.889 836.474Z" stroke="white"/>
    <path d="M536.294 427.888C425.841 477.52 296.068 428.214 246.436 317.762C196.805 207.309 246.11 77.5356 356.563 27.9043C358.908 26.8503 362.048 26.8436 365.905 27.9377C369.756 29.0298 374.265 31.2034 379.301 34.4267C389.371 40.872 401.477 51.469 414.529 65.8204C440.629 94.5193 470.462 138.175 495.314 193.483C520.163 248.784 533.662 306.005 539.285 350.145C542.097 372.216 542.938 391.003 542.248 404.634C541.902 411.452 541.175 416.961 540.128 420.94C539.604 422.93 539.005 424.518 538.346 425.69C537.683 426.869 536.987 427.577 536.294 427.888Z" stroke="white"/>
    <path d="M620.435 662.083C506.373 702.736 380.95 643.226 340.297 529.163C299.643 415.101 359.153 289.678 473.216 249.025C475.638 248.161 478.768 248.405 482.526 249.804C486.277 251.2 490.599 253.727 495.361 257.342C504.884 264.571 516.105 276.1 527.97 291.448C551.695 322.14 577.946 368.038 598.303 425.154C618.657 482.262 627.544 540.379 629.625 584.827C630.665 607.052 630.003 625.846 628.227 639.378C627.339 646.147 626.174 651.58 624.812 655.462C624.131 657.404 623.407 658.939 622.657 660.055C621.901 661.177 621.151 661.828 620.435 662.083Z" stroke="white"/>
    <path d="M689.422 855.64C575.359 896.293 449.937 836.783 409.284 722.72C368.63 608.658 428.14 483.235 542.203 442.582C544.625 441.718 547.755 441.962 551.513 443.361C555.264 444.757 559.585 447.284 564.348 450.899C573.871 458.128 585.092 469.657 596.956 485.005C620.682 515.697 646.933 561.595 667.29 618.711C687.644 675.819 696.531 733.936 698.612 778.384C699.652 800.609 698.99 819.402 697.214 832.935C696.325 839.704 695.16 845.137 693.799 849.019C693.118 850.961 692.394 852.496 691.643 853.612C690.888 854.734 690.138 855.385 689.422 855.64Z" stroke="white"/>
    <path d="M539.037 433.7C424.975 474.354 299.552 414.844 258.899 300.781C218.245 186.718 277.755 61.2958 391.818 20.6423C394.24 19.779 397.37 20.0229 401.128 21.4215C404.879 22.8177 409.201 25.3444 413.963 28.9595C423.486 36.1883 434.707 47.718 446.572 63.0658C470.297 93.7571 496.548 139.655 516.905 196.772C537.259 253.879 546.146 311.996 548.227 356.444C549.267 378.669 548.605 397.463 546.829 410.996C545.941 417.764 544.776 423.198 543.414 427.08C542.733 429.022 542.01 430.557 541.259 431.672C540.503 432.795 539.753 433.445 539.037 433.7Z" stroke="white"/>
    <path d="M605.156 673.228C488.355 705.175 367.771 636.386 335.825 519.585C303.879 402.784 372.667 282.201 489.468 250.254C491.949 249.576 495.051 250.055 498.693 251.733C502.329 253.408 506.447 256.253 510.924 260.216C519.876 268.142 530.196 280.484 540.871 296.682C562.217 329.073 584.936 376.818 600.933 435.306C616.928 493.784 621.412 552.406 620.138 596.884C619.502 619.124 617.426 637.815 614.635 651.175C613.239 657.858 611.669 663.188 610.019 666.956C609.193 668.841 608.356 670.318 607.523 671.374C606.686 672.436 605.888 673.028 605.156 673.228Z" stroke="white"/>
    <path d="M659.366 871.436C542.565 903.382 421.982 834.594 390.036 717.793C358.09 600.992 426.878 480.408 543.679 448.462C546.159 447.784 549.262 448.263 552.904 449.941C556.54 451.615 560.658 454.46 565.135 458.424C574.087 466.35 584.407 478.692 595.082 494.89C616.428 527.281 639.147 575.026 655.144 633.514C671.139 691.992 675.623 750.613 674.349 795.092C673.713 817.332 671.637 836.023 668.846 849.383C667.45 856.065 665.879 861.396 664.229 865.164C663.404 867.049 662.567 868.525 661.734 869.581C660.897 870.644 660.099 871.236 659.366 871.436Z" stroke="white"/>
    <path d="M541.192 439.368C424.391 471.314 303.807 402.525 271.861 285.724C239.915 168.923 308.703 48.34 425.504 16.3939C427.985 15.7155 431.087 16.1945 434.729 17.8722C438.365 19.547 442.484 22.392 446.96 26.3555C455.912 34.2812 466.232 46.6234 476.907 62.8213C498.253 95.2125 520.973 142.958 536.969 201.445C552.964 259.924 557.448 318.545 556.175 363.024C555.538 385.264 553.462 403.954 550.671 417.314C549.276 423.997 547.705 429.327 546.055 433.096C545.229 434.981 544.392 436.457 543.559 437.513C542.722 438.575 541.925 439.167 541.192 439.368Z" stroke="white"/>
    <path d="M590.292 682.498C471.547 706.217 356.057 629.184 332.337 510.438C308.618 391.693 385.652 276.203 504.397 252.484C506.919 251.98 509.981 252.674 513.497 254.602C517.006 256.526 520.917 259.652 525.106 263.918C533.483 272.449 542.917 285.481 552.436 302.384C571.47 336.186 590.803 385.4 602.68 444.861C614.555 504.313 614.938 563.104 610.565 607.386C608.378 629.527 605.003 648.027 601.287 661.16C599.429 667.729 597.49 672.937 595.581 676.581C594.626 678.404 593.688 679.818 592.783 680.813C591.874 681.815 591.037 682.35 590.292 682.498Z" stroke="white"/>
    <path d="M630.543 884.006C511.798 907.725 396.308 830.691 372.588 711.946C348.869 593.2 425.903 477.71 544.648 453.991C547.17 453.487 550.232 454.182 553.748 456.109C557.257 458.034 561.168 461.159 565.357 465.425C573.734 473.956 583.168 486.988 592.686 503.892C611.721 537.693 631.054 586.907 642.931 646.368C654.806 705.82 655.189 764.612 650.816 808.893C648.629 831.034 645.254 849.535 641.538 862.668C639.68 869.237 637.741 874.444 635.832 878.088C634.877 879.911 633.939 881.325 633.034 882.321C632.125 883.322 631.288 883.857 630.543 884.006Z" stroke="white"/>
    <path d="M542.801 444.743C424.056 468.462 308.566 391.428 284.847 272.683C261.128 153.938 338.161 38.4477 456.907 14.7286C459.428 14.2249 462.49 14.9193 466.006 16.8469C469.516 18.7713 473.426 21.8968 477.615 26.163C485.992 34.6939 495.426 47.726 504.945 64.6292C523.979 98.4308 543.312 147.645 555.189 207.106C567.065 266.558 567.448 325.349 563.074 369.63C560.887 391.772 557.513 410.272 553.797 423.405C551.938 429.974 549.999 435.182 548.09 438.826C547.135 440.649 546.197 442.063 545.293 443.058C544.383 444.06 543.546 444.594 542.801 444.743Z" stroke="white"/>
    <path d="M575.457 690.396C455.39 706.113 345.316 621.521 329.599 501.455C313.881 381.388 398.473 271.314 518.54 255.596C521.09 255.263 524.098 256.16 527.477 258.319C530.85 260.474 534.543 263.854 538.437 268.391C546.224 277.464 554.765 291.098 563.13 308.601C579.859 343.601 595.854 393.999 603.724 454.121C611.593 514.235 608.04 572.92 600.712 616.809C597.048 638.755 592.443 656.987 587.856 669.842C585.562 676.272 583.278 681.338 581.13 684.846C580.055 686.601 579.024 687.949 578.055 688.882C577.081 689.82 576.21 690.298 575.457 690.396Z" stroke="white"/>
    <path d="M602.128 894.146C482.061 909.863 371.987 825.271 356.27 705.204C340.553 585.138 425.145 475.063 545.212 459.346C547.761 459.012 550.77 459.91 554.149 462.069C557.522 464.224 561.214 467.604 565.108 472.141C572.895 481.214 581.436 494.848 589.802 512.35C606.53 547.35 622.525 597.748 630.396 657.871C638.265 717.985 634.712 776.67 627.384 820.559C623.72 842.504 619.114 860.737 614.527 873.592C612.233 880.022 609.95 885.088 607.801 888.596C606.726 890.351 605.696 891.699 604.727 892.632C603.752 893.57 602.881 894.047 602.128 894.146Z" stroke="white"/>
    <path d="M543.988 449.996C423.921 465.713 313.847 381.121 298.13 261.054C282.413 140.987 367.005 30.9129 487.071 15.1959C489.621 14.8621 492.629 15.7598 496.008 17.9186C499.382 20.0735 503.074 23.4538 506.968 27.9908C514.755 37.0634 523.296 50.6978 531.661 68.2002C548.39 103.2 564.385 153.598 572.256 213.721C580.125 273.834 576.571 332.519 569.244 376.409C565.579 398.354 560.974 416.587 556.387 429.442C554.093 435.871 551.81 440.937 549.661 444.446C548.586 446.201 547.555 447.549 546.587 448.481C545.612 449.42 544.741 449.897 543.988 449.996Z" stroke="white"/>
    <path d="M559.302 697.627C438.426 704.842 334.587 612.702 327.372 491.826C320.157 370.95 412.297 267.112 533.173 259.896C535.74 259.743 538.677 260.851 541.896 263.242C545.109 265.63 548.554 269.262 552.118 274.062C559.247 283.661 566.805 297.863 573.916 315.912C588.136 352.004 600.539 403.404 604.152 463.932C607.765 524.45 600.084 582.739 589.681 626.003C584.479 647.635 578.6 665.498 573.119 677.997C570.377 684.25 567.742 689.142 565.352 692.49C564.156 694.165 563.033 695.437 562 696.299C560.962 697.166 560.06 697.581 559.302 697.627Z" stroke="white"/>
    <path d="M571.546 902.748C450.67 909.963 346.832 817.823 339.616 696.947C332.401 576.071 424.541 472.233 545.417 465.018C547.984 464.865 550.922 465.972 554.14 468.364C557.353 470.751 560.798 474.383 564.362 479.183C571.491 488.782 579.049 502.985 586.16 521.033C600.38 557.125 612.784 608.525 616.397 669.053C620.009 729.572 612.328 787.86 601.925 831.124C596.723 852.757 590.844 870.619 585.363 883.119C582.621 889.371 579.986 894.264 577.596 897.612C576.4 899.286 575.277 900.559 574.245 901.421C573.206 902.288 572.304 902.703 571.546 902.748Z" stroke="white"/>
    <path d="M544.855 455.604C423.98 462.819 320.141 370.679 312.926 249.803C305.711 128.927 397.851 25.0886 518.727 17.8734C521.293 17.7201 524.231 18.8277 527.45 21.2192C530.662 23.6066 534.107 27.2386 537.672 32.0389C544.8 41.6377 552.359 55.8402 559.47 73.8888C573.69 109.981 586.093 161.381 589.706 221.909C593.319 282.427 585.638 340.716 575.235 383.98C570.033 405.612 564.154 423.475 558.672 435.974C555.931 442.227 553.296 447.119 550.905 450.467C549.71 452.142 548.586 453.414 547.554 454.276C546.516 455.143 545.614 455.558 544.855 455.604Z" stroke="white"/>
    <path d="M543.728 703.348C422.64 702.56 325.118 603.759 325.907 482.67C326.695 361.582 425.496 264.06 546.585 264.848C549.156 264.865 552.014 266.164 555.068 268.763C558.116 271.358 561.313 275.21 564.553 280.235C571.031 290.284 577.634 304.955 583.537 323.434C595.341 360.387 604.32 412.494 603.925 473.128C603.53 533.754 592.014 591.407 578.775 633.889C572.155 655.13 565.108 672.565 558.812 684.675C555.663 690.733 552.711 695.44 550.105 698.623C548.801 700.215 547.596 701.41 546.509 702.202C545.415 702.999 544.488 703.353 543.728 703.348Z" stroke="white"/>
    <path d="M542.39 908.831C421.302 908.042 323.78 809.241 324.569 688.152C325.357 567.064 424.158 469.542 545.247 470.331C547.818 470.347 550.676 471.647 553.73 474.246C556.778 476.84 559.975 480.692 563.215 485.717C569.693 495.766 576.296 510.437 582.199 528.916C594.003 565.869 602.982 617.976 602.587 678.611C602.192 739.236 590.676 796.889 577.437 839.371C570.817 860.612 563.77 878.048 557.474 890.157C554.325 896.215 551.373 900.922 548.767 904.105C547.463 905.697 546.258 906.893 545.171 907.684C544.078 908.481 543.15 908.836 542.39 908.831Z" stroke="white"/>
    <path d="M545.308 460.899C424.219 460.11 326.697 361.309 327.486 240.221C328.275 119.132 427.076 21.6101 548.164 22.3989C550.736 22.4157 553.594 23.7149 556.647 26.3139C559.695 28.9084 562.892 32.7602 566.132 37.7855C572.61 47.8344 579.214 62.5054 585.117 80.9844C596.921 117.937 605.9 170.045 605.505 230.679C605.11 291.304 593.594 348.958 580.354 391.439C573.734 412.681 566.687 430.116 560.392 442.226C557.243 448.283 554.291 452.991 551.684 456.174C550.38 457.766 549.175 458.961 548.089 459.753C546.995 460.549 546.068 460.904 545.308 460.899Z" stroke="white"/>
    <path d="M522.852 709.258C402.284 698.012 313.662 591.155 324.909 470.588C336.155 350.02 443.011 261.398 563.579 272.644C566.139 272.883 568.874 274.424 571.692 277.277C574.504 280.125 577.357 284.239 580.15 289.525C585.736 300.096 591.048 315.283 595.332 334.203C603.899 372.037 608.344 424.726 602.712 485.099C597.081 545.463 580.628 601.907 563.768 643.086C555.338 663.676 546.811 680.437 539.493 691.958C535.832 697.72 532.484 702.155 529.613 705.101C528.176 706.575 526.873 707.661 525.721 708.356C524.563 709.056 523.608 709.329 522.852 709.258Z" stroke="white"/>
    <path d="M503.767 913.857C383.2 902.61 294.578 795.754 305.824 675.186C317.071 554.619 423.927 465.996 544.494 477.243C547.055 477.482 549.79 479.023 552.607 481.876C555.42 484.724 558.272 488.838 561.066 494.124C566.652 504.695 571.963 519.882 576.247 538.802C584.815 576.636 589.259 629.324 583.628 689.698C577.997 750.062 561.543 806.505 544.683 847.684C536.253 868.274 527.726 885.036 520.408 896.557C516.748 902.319 513.4 906.754 510.528 909.7C509.092 911.173 507.788 912.26 506.637 912.955C505.479 913.654 504.524 913.927 503.767 913.857Z" stroke="white"/>
    <path d="M545.369 467.855C424.802 456.608 336.18 349.752 347.426 229.184C358.673 108.617 465.529 19.9945 586.097 31.2409C588.657 31.4797 591.392 33.0211 594.209 35.8741C597.022 38.7222 599.874 42.8358 602.668 48.1222C608.254 58.6932 613.565 73.8798 617.849 92.7997C626.417 130.634 630.861 183.322 625.23 243.696C619.599 304.06 603.145 360.503 586.285 401.682C577.855 422.273 569.328 439.034 562.01 450.555C558.35 456.317 555.002 460.752 552.13 463.698C550.694 465.172 549.39 466.258 548.239 466.953C547.081 467.652 546.126 467.925 545.369 467.855Z" stroke="white"/>
    <path d="M503.689 713.018C384.361 692.43 304.317 579.005 324.905 459.677C345.494 340.349 458.919 260.305 578.247 280.894C580.78 281.331 583.387 283.08 585.974 286.144C588.557 289.202 591.081 293.525 593.455 299.013C598.202 309.986 602.316 325.54 605.116 344.735C610.715 383.122 611.049 435.996 600.739 495.748C590.431 555.492 569.638 610.485 549.627 650.228C539.621 670.1 529.816 686.148 521.624 697.064C517.527 702.525 513.844 706.686 510.752 709.4C509.206 710.757 507.821 711.739 506.619 712.342C505.41 712.949 504.437 713.147 503.689 713.018Z" stroke="white"/>
    <path d="M468.751 915.511C349.423 894.922 269.379 781.497 289.968 662.17C310.556 542.842 423.981 462.798 543.309 483.386C545.843 483.823 548.45 485.573 551.037 488.636C553.619 491.694 556.143 496.017 558.517 501.505C563.264 512.478 567.379 528.032 570.179 547.228C575.778 585.614 576.111 638.488 565.802 698.241C555.494 757.985 534.7 812.977 514.689 852.72C504.683 872.593 494.879 888.64 486.687 899.557C482.589 905.017 478.907 909.179 475.815 911.892C474.268 913.249 472.884 914.231 471.682 914.835C470.473 915.442 469.5 915.64 468.751 915.511Z" stroke="white"/>
    <path d="M544.912 474.095C425.584 453.506 345.54 340.082 366.129 220.754C386.718 101.426 500.142 21.3819 619.47 41.9705C622.004 42.4077 624.611 44.157 627.198 47.2206C629.781 50.2787 632.305 54.6017 634.679 60.0893C639.426 71.0628 643.54 86.6164 646.34 105.812C651.939 144.198 652.272 197.073 641.963 256.825C631.655 316.569 610.862 371.562 590.85 411.305C580.844 431.177 571.04 447.224 562.848 458.141C558.75 463.602 555.068 467.763 551.976 470.477C550.429 471.834 549.045 472.816 547.843 473.419C546.634 474.026 545.661 474.224 544.912 474.095Z" stroke="white"/>
    <path d="M484.048 715.294C366.712 685.374 295.847 566 325.766 448.663C355.686 331.327 475.06 260.461 592.397 290.381C594.888 291.016 597.35 292.965 599.687 296.223C602.021 299.475 604.197 303.983 606.131 309.641C610 320.954 612.877 336.783 614.157 356.14C616.716 394.848 612.886 447.584 597.904 506.34C582.924 565.086 557.866 618.271 534.787 656.315C523.248 675.338 512.21 690.564 503.184 700.802C498.67 705.923 494.671 709.781 491.375 712.243C489.726 713.474 488.269 714.344 487.023 714.851C485.77 715.361 484.785 715.482 484.048 715.294Z" stroke="white"/>
    <path d="M433.276 914.41C315.939 884.491 245.074 765.116 274.994 647.78C304.913 530.443 424.288 459.578 541.624 489.497C544.116 490.133 546.577 492.082 548.915 495.34C551.249 498.592 553.424 503.1 555.359 508.757C559.227 520.071 562.104 535.9 563.384 555.256C565.944 593.964 562.113 646.701 547.131 705.456C532.151 764.203 507.093 817.388 484.015 855.432C472.475 874.455 461.438 889.68 452.412 899.918C447.897 905.039 443.899 908.898 440.602 911.359C438.954 912.591 437.496 913.461 436.251 913.967C434.998 914.477 434.012 914.598 433.276 914.41Z" stroke="white"/>
    <path d="M543.955 480.359C426.618 450.44 355.753 331.065 385.672 213.729C415.592 96.3921 534.967 25.5267 652.303 55.4463C654.795 56.0816 657.256 58.0308 659.594 61.2885C661.927 64.5405 664.103 69.0488 666.038 74.7063C669.906 86.0194 672.783 101.849 674.063 121.205C676.623 159.913 672.792 212.649 657.81 271.405C642.83 330.152 617.772 383.337 594.694 421.381C583.154 440.404 572.117 455.629 563.091 465.867C558.576 470.988 554.577 474.847 551.281 477.308C549.632 478.539 548.175 479.409 546.929 479.916C545.676 480.426 544.691 480.547 543.955 480.359Z" stroke="white"/>
    <path d="M466.319 716.014C351.39 677.877 289.138 553.793 327.276 438.864C365.413 323.935 489.497 261.684 604.426 299.821C606.866 300.631 609.183 302.749 611.285 306.164C613.383 309.572 615.235 314.223 616.765 320.003C619.824 331.561 621.575 347.554 621.484 366.953C621.302 405.745 613.754 458.079 594.657 515.629C575.562 573.17 546.808 624.451 521.099 660.769C508.244 678.929 496.158 693.336 486.431 702.911C481.566 707.7 477.304 711.266 473.843 713.488C472.111 714.6 470.596 715.365 469.317 715.782C468.031 716.203 467.04 716.253 466.319 716.014Z" stroke="white"/>
    <path d="M401.602 911.04C286.673 872.903 224.422 748.818 262.559 633.89C300.696 518.961 424.78 456.709 539.709 494.847C542.149 495.656 544.467 497.775 546.568 501.189C548.667 504.598 550.518 509.249 552.048 515.029C555.107 526.587 556.858 542.58 556.767 561.979C556.585 600.771 549.037 653.105 529.94 710.654C510.846 768.196 482.091 819.477 456.382 855.795C443.527 873.954 431.441 888.362 421.714 897.936C416.849 902.725 412.588 906.292 409.126 908.514C407.394 909.626 405.879 910.391 404.601 910.808C403.315 911.228 402.323 911.279 401.602 911.04Z" stroke="white"/>
    <path d="M542.679 485.899C427.75 447.762 365.498 323.678 403.635 208.749C441.773 93.8204 565.857 31.5686 680.786 69.7058C683.226 70.5156 685.543 72.6338 687.645 76.0486C689.743 79.4574 691.595 84.1082 693.125 89.8882C696.184 101.446 697.935 117.439 697.844 136.838C697.661 175.63 690.113 227.964 671.016 285.514C651.922 343.055 623.168 394.336 597.459 430.654C584.604 448.814 572.518 463.221 562.791 472.796C557.926 477.585 553.664 481.151 550.202 483.373C548.471 484.485 546.956 485.25 545.677 485.667C544.391 486.088 543.4 486.138 542.679 485.899Z" stroke="white"/>
    <path d="M444.75 715.201C333.524 667.328 282.166 538.353 330.039 427.127C377.912 315.901 506.888 264.543 618.114 312.416C620.475 313.433 622.602 315.743 624.403 319.325C626.2 322.902 627.645 327.695 628.673 333.585C630.727 345.363 631.097 361.447 629.339 380.766C625.824 419.399 613.806 470.891 589.834 526.586C565.865 582.274 532.81 630.894 504.075 664.868C489.707 681.856 476.427 695.171 465.914 703.874C460.655 708.228 456.103 711.414 452.463 713.331C450.642 714.29 449.067 714.922 447.757 715.228C446.44 715.536 445.447 715.501 444.75 715.201Z" stroke="white"/>
    <path d="M363.511 903.946C252.285 856.073 200.927 727.097 248.801 615.872C296.674 504.646 425.649 453.288 536.875 501.161C539.237 502.178 541.363 504.487 543.164 508.07C544.961 511.647 546.406 516.439 547.434 522.33C549.488 534.108 549.858 550.192 548.1 569.511C544.585 608.144 532.567 659.636 508.595 715.331C484.627 771.018 451.571 819.639 422.836 853.613C408.468 870.601 395.189 883.916 384.675 892.619C379.416 896.973 374.864 900.159 371.224 902.076C369.403 903.035 367.828 903.667 366.518 903.973C365.201 904.281 364.209 904.246 363.511 903.946Z" stroke="white"/>
    <path d="M540.603 492.501C429.377 444.628 378.02 315.653 425.893 204.427C473.766 93.2011 602.741 41.8436 713.967 89.7168C716.329 90.7334 718.456 93.0429 720.256 96.6257C722.053 100.202 723.499 104.995 724.526 110.885C726.581 122.663 726.951 138.748 725.193 158.067C721.677 196.7 709.66 248.191 685.687 303.887C661.719 359.574 628.664 408.194 599.929 442.168C585.56 459.156 572.281 472.472 561.767 481.175C556.508 485.528 551.956 488.715 548.316 490.632C546.495 491.59 544.92 492.222 543.611 492.528C542.293 492.836 541.301 492.802 540.603 492.501Z" stroke="white"/>
    <path d="M421.481 712.215C315.212 654.163 276.124 520.955 334.175 414.686C392.227 308.418 525.434 269.329 631.703 327.381C633.96 328.614 635.862 331.112 637.32 334.847C638.775 338.576 639.766 343.482 640.239 349.443C641.185 361.361 640.051 377.41 636.497 396.48C629.389 434.616 612.615 484.76 583.546 537.974C554.481 591.179 517.03 636.5 485.248 667.642C469.356 683.214 454.891 695.231 443.61 702.914C437.968 706.757 433.138 709.505 429.335 711.073C427.433 711.858 425.805 712.34 424.473 712.522C423.132 712.706 422.148 712.579 421.481 712.215Z" stroke="white"/>
    <path d="M322.97 892.55C216.701 834.498 177.613 701.291 235.664 595.022C293.715 488.753 426.923 449.665 533.192 507.716C535.449 508.949 537.35 511.447 538.808 515.182C540.264 518.911 541.255 523.817 541.728 529.778C542.674 541.697 541.54 557.745 537.985 576.816C530.877 614.951 514.103 665.095 485.035 718.309C455.97 771.514 418.519 816.835 386.736 847.977C370.845 863.549 356.38 875.566 345.099 883.249C339.457 887.092 334.627 889.84 330.824 891.408C328.921 892.193 327.294 892.675 325.962 892.857C324.621 893.041 323.636 892.914 322.97 892.55Z" stroke="white"/>
    <path d="M537.712 499.44C431.443 441.389 392.355 308.181 450.407 201.912C508.458 95.6433 641.666 56.5552 747.935 114.607C750.191 115.839 752.093 118.337 753.551 122.073C755.007 125.801 755.998 130.708 756.471 136.668C757.416 148.587 756.282 164.636 752.728 183.706C745.62 221.842 728.846 271.986 699.777 325.199C670.713 378.405 633.262 423.726 601.479 454.868C585.587 470.439 571.122 482.457 559.842 490.14C554.199 493.983 549.369 496.731 545.566 498.299C543.664 499.084 542.037 499.565 540.704 499.748C539.364 499.932 538.379 499.805 537.712 499.44Z" stroke="white"/>
    <path d="M398.952 707.171C298.468 639.599 271.789 503.362 339.361 402.878C406.934 302.395 543.171 275.715 643.654 343.288C645.788 344.723 647.452 347.385 648.561 351.239C649.667 355.085 650.203 360.062 650.126 366.041C649.972 377.996 647.369 393.873 642.076 412.536C631.493 449.857 610.182 498.247 576.345 548.563C542.514 598.872 501.055 640.559 466.545 668.648C449.289 682.693 433.781 693.33 421.842 699.944C415.87 703.252 410.808 705.545 406.877 706.757C404.911 707.363 403.246 707.693 401.903 707.753C400.551 707.812 399.582 707.595 398.952 707.171Z" stroke="white"/>
    <path d="M284.284 877.688C183.8 810.116 157.121 673.879 224.693 573.396C292.266 472.912 428.503 446.232 528.986 513.805C531.12 515.24 532.784 517.902 533.893 521.756C534.999 525.602 535.535 530.579 535.458 536.558C535.304 548.513 532.701 564.39 527.408 583.053C516.825 620.374 495.514 668.764 461.677 719.08C427.846 769.389 386.387 811.076 351.877 839.165C334.621 853.21 319.113 863.847 307.174 870.461C301.202 873.769 296.141 876.062 292.209 877.274C290.243 877.88 288.578 878.211 287.235 878.27C285.883 878.329 284.914 878.112 284.284 877.688Z" stroke="white"/>
    <path d="M534.248 505.979C433.764 438.406 407.084 302.169 474.657 201.686C542.23 101.202 678.467 74.5224 778.95 142.095C781.084 143.53 782.748 146.192 783.856 150.046C784.963 153.892 785.499 158.87 785.422 164.848C785.268 176.803 782.664 192.68 777.372 211.343C766.789 248.664 745.478 297.054 711.641 347.37C677.809 397.679 636.351 439.366 601.841 467.455C584.585 481.501 569.077 492.137 557.138 498.751C551.166 502.059 546.104 504.352 542.173 505.564C540.207 506.17 538.542 506.501 537.198 506.56C535.847 506.62 534.878 506.402 534.248 505.979Z" stroke="white"/>
    <path d="M378.477 700.633C284.145 624.708 269.222 486.688 345.147 392.356C421.071 298.024 559.091 283.102 653.423 359.026C655.426 360.638 656.856 363.433 657.631 367.367C658.404 371.295 658.512 376.299 657.924 382.249C656.748 394.148 652.794 409.743 645.925 427.885C632.187 464.163 606.812 510.552 568.793 557.788C530.78 605.017 485.907 643.003 449.119 668.036C430.725 680.552 414.363 689.823 401.902 695.391C395.669 698.176 390.429 700.026 386.409 700.898C384.398 701.334 382.711 701.52 381.367 701.464C380.015 701.408 379.069 701.109 378.477 700.633Z" stroke="white"/>
    <path d="M249.637 860.711C155.305 784.786 140.382 646.766 216.307 552.434C292.231 458.102 430.251 443.18 524.583 519.104C526.586 520.716 528.016 523.511 528.791 527.445C529.564 531.373 529.673 536.377 529.084 542.327C527.908 554.226 523.955 569.821 517.085 587.963C503.347 624.241 477.972 670.63 439.953 717.866C401.94 765.095 357.067 803.081 320.279 828.114C301.885 840.63 285.523 849.901 273.062 855.469C266.829 858.254 261.59 860.104 257.569 860.976C255.558 861.412 253.871 861.598 252.527 861.542C251.176 861.486 250.229 861.187 249.637 860.711Z" stroke="white"/>
    <path d="M530.495 511.756C436.163 435.832 421.24 297.812 497.165 203.48C573.089 109.148 711.109 94.2255 805.441 170.15C807.444 171.762 808.874 174.557 809.649 178.491C810.422 182.418 810.53 187.423 809.942 193.373C808.766 205.271 804.813 220.867 797.943 239.008C784.205 275.287 758.83 321.675 720.811 368.912C682.798 416.141 637.925 454.127 601.137 479.159C582.743 491.676 566.381 500.947 553.92 506.515C547.687 509.3 542.447 511.15 538.427 512.022C536.416 512.458 534.729 512.644 533.385 512.588C532.034 512.532 531.087 512.233 530.495 511.756Z" stroke="white"/>
    <path d="M360.732 693.327C272.477 610.416 268.145 471.659 351.056 383.405C433.966 295.15 572.723 290.818 660.977 373.729C662.851 375.489 664.064 378.385 664.536 382.367C665.007 386.342 664.732 391.34 663.691 397.228C661.609 409.002 656.475 424.249 648.239 441.813C631.769 476.935 602.924 521.249 561.407 565.442C519.896 609.629 472.251 644.074 433.658 666.223C414.361 677.298 397.339 685.291 384.489 689.89C378.061 692.191 372.696 693.636 368.62 694.197C366.582 694.478 364.886 694.535 363.55 694.377C362.207 694.218 361.285 693.847 360.732 693.327Z" stroke="white"/>
    <path d="M220.036 843.093C131.782 760.183 127.45 621.426 210.36 533.171C293.271 444.917 432.027 440.585 520.282 523.495C522.156 525.256 523.368 528.152 523.84 532.134C524.311 536.109 524.037 541.107 522.995 546.995C520.913 558.768 515.78 574.016 507.544 591.58C491.074 626.702 462.229 671.016 420.712 715.209C379.201 759.395 331.556 793.841 292.963 815.99C273.666 827.064 256.644 835.058 243.794 839.657C237.366 841.957 232 843.402 227.925 843.964C225.886 844.245 224.19 844.302 222.855 844.144C221.511 843.984 220.59 843.614 220.036 843.093Z" stroke="white"/>
    <path d="M526.738 516.622C438.484 433.712 434.151 294.955 517.062 206.7C599.972 118.446 738.729 114.114 826.984 197.024C828.858 198.785 830.07 201.681 830.542 205.663C831.013 209.638 830.738 214.636 829.697 220.524C827.615 232.297 822.482 247.545 814.245 265.108C797.775 300.231 768.93 344.545 727.413 388.738C685.903 432.924 638.258 467.37 599.665 489.518C580.368 500.593 563.345 508.586 550.495 513.186C544.068 515.486 538.702 516.931 534.627 517.493C532.588 517.774 530.892 517.831 529.556 517.672C528.213 517.513 527.292 517.142 526.738 516.622Z" stroke="white"/>
    <path d="M342.313 683.979C261.177 594.09 268.273 455.448 358.161 374.312C448.05 293.176 586.693 300.271 667.829 390.16C669.552 392.069 670.522 395.054 670.665 399.062C670.807 403.062 670.122 408.021 668.6 413.803C665.557 425.365 659.187 440.139 649.534 456.966C630.231 490.614 597.838 532.406 552.827 573.034C507.823 613.656 457.506 644.066 417.222 662.966C397.079 672.416 379.457 678.982 366.272 682.508C359.677 684.273 354.211 685.271 350.103 685.496C348.049 685.608 346.353 685.525 345.036 685.258C343.71 684.988 342.822 684.543 342.313 683.979Z" stroke="white"/>
    <path d="M189.776 821.666C108.64 731.777 115.736 593.134 205.624 511.998C295.513 430.862 434.156 437.958 515.292 527.847C517.015 529.755 517.985 532.741 518.127 536.748C518.27 540.749 517.585 545.707 516.063 551.49C513.02 563.052 506.65 577.826 496.997 594.652C477.694 628.301 445.301 670.093 400.29 710.721C355.286 751.343 304.969 781.753 264.685 800.652C244.542 810.102 226.92 816.669 213.735 820.195C207.14 821.959 201.674 822.958 197.566 823.182C195.512 823.295 193.816 823.212 192.498 822.944C191.173 822.675 190.285 822.23 189.776 821.666Z" stroke="white"/>
    <path d="M522.291 521.528C441.155 431.639 448.25 292.996 538.139 211.86C628.028 130.724 766.67 137.82 847.806 227.709C849.529 229.617 850.499 232.603 850.642 236.61C850.785 240.611 850.1 245.569 848.578 251.352C845.534 262.914 839.164 277.688 829.511 294.514C810.208 328.163 777.816 369.955 732.805 410.583C687.8 451.205 637.483 481.615 597.2 500.514C577.057 509.964 559.435 516.531 546.25 520.057C539.655 521.821 534.189 522.82 530.081 523.044C528.026 523.157 526.331 523.074 525.013 522.806C523.687 522.537 522.8 522.092 522.291 521.528Z" stroke="white"/>
    <path d="M324.003 672.656C250.87 576.144 269.822 438.619 366.335 365.486C462.847 292.353 600.371 311.306 673.504 407.818C675.057 409.867 675.768 412.925 675.567 416.93C675.366 420.928 674.258 425.81 672.246 431.44C668.223 442.699 660.61 456.872 649.551 472.81C627.435 504.68 591.579 543.542 543.251 580.163C494.931 616.778 442.193 642.764 400.437 658.14C379.559 665.829 361.439 670.861 348 673.244C341.278 674.437 335.746 674.963 331.634 674.835C329.578 674.771 327.896 674.543 326.606 674.163C325.308 673.781 324.462 673.262 324.003 672.656Z" stroke="white"/>
    <path d="M160.226 796.762C87.0926 700.25 106.045 562.725 202.557 489.592C299.069 416.459 436.594 435.411 509.727 531.923C511.28 533.973 511.99 537.031 511.789 541.036C511.588 545.033 510.481 549.915 508.469 555.546C504.446 566.804 496.833 580.978 485.773 596.915C463.657 628.786 427.802 667.647 379.474 704.268C331.153 740.883 278.415 766.869 236.66 782.246C215.782 789.935 197.662 794.966 184.223 797.35C177.501 798.542 171.969 799.069 167.857 798.94C165.8 798.876 164.119 798.648 162.828 798.269C161.531 797.887 160.685 797.367 160.226 796.762Z" stroke="white"/>
    <path d="M517.242 526.228C444.109 429.716 463.062 292.191 559.574 219.058C656.086 145.925 793.611 164.878 866.744 261.39C868.297 263.439 869.007 266.497 868.806 270.502C868.605 274.5 867.498 279.382 865.486 285.012C861.462 296.271 853.849 310.444 842.79 326.382C820.674 358.252 784.819 397.114 736.491 433.735C688.17 470.35 635.432 496.336 593.677 511.712C572.798 519.401 554.678 524.433 541.239 526.816C534.517 528.009 528.986 528.535 524.874 528.407C522.817 528.343 521.135 528.115 519.845 527.735C518.547 527.353 517.701 526.834 517.242 526.228Z" stroke="white"/>
    <path d="M308.086 660.901C242.785 558.926 272.514 423.323 374.488 358.021C476.462 292.719 612.066 322.448 677.367 424.422C678.754 426.587 679.221 429.692 678.705 433.668C678.19 437.638 676.702 442.417 674.252 447.872C669.354 458.778 660.648 472.308 648.367 487.325C623.809 517.354 585.004 553.269 533.941 585.969C482.885 618.663 428.264 640.413 385.427 652.452C364.007 658.472 345.547 662.06 331.963 663.378C325.168 664.037 319.612 664.126 315.522 663.674C313.477 663.448 311.819 663.088 310.562 662.608C309.299 662.125 308.496 661.54 308.086 660.901Z" stroke="white"/>
    <path d="M135.041 771.717C69.7392 669.743 99.4681 534.139 201.442 468.838C303.416 403.536 439.02 433.265 504.322 535.239C505.709 537.404 506.176 540.509 505.66 544.485C505.144 548.455 503.656 553.234 501.207 558.688C496.309 569.595 487.603 583.125 475.322 598.141C450.764 628.17 411.958 664.086 360.895 696.785C309.84 729.48 255.218 751.23 212.381 763.269C190.962 769.289 172.502 772.877 158.917 774.195C152.122 774.854 146.566 774.942 142.477 774.49C140.432 774.264 138.773 773.905 137.517 773.425C136.253 772.942 135.451 772.357 135.041 771.717Z" stroke="white"/>
    <path d="M512.263 530.152C446.961 428.178 476.69 292.574 578.664 227.272C680.638 161.971 816.242 191.699 881.544 293.674C882.93 295.839 883.398 298.943 882.881 302.92C882.366 306.889 880.878 311.669 878.428 317.123C873.53 328.03 864.825 341.56 852.544 356.576C827.985 386.605 789.18 422.521 738.117 455.22C687.062 487.914 632.44 509.664 589.603 521.703C568.184 527.723 549.724 531.312 536.139 532.629C529.344 533.288 523.788 533.377 519.699 532.925C517.654 532.699 515.995 532.34 514.739 531.859C513.475 531.376 512.672 530.792 512.263 530.152Z" stroke="white"/>
    <path d="M288.436 643.387C234.177 535.133 277.949 403.39 386.203 349.131C494.458 294.872 626.201 338.644 680.459 446.898C681.612 449.197 681.751 452.334 680.821 456.234C679.893 460.128 677.912 464.725 674.905 469.893C668.891 480.226 658.816 492.769 645.029 506.417C617.46 533.707 575.105 565.36 520.897 592.529C466.698 619.695 410.098 635.603 366.236 643.088C344.303 646.83 325.569 648.465 311.921 648.352C305.094 648.296 299.56 647.802 295.54 646.924C293.53 646.485 291.918 645.954 290.719 645.345C289.513 644.732 288.776 644.066 288.436 643.387Z" stroke="white"/>
    <path d="M104.733 735.462C50.4741 627.208 94.2462 495.465 202.501 441.206C310.755 386.947 442.498 430.719 496.757 538.974C497.909 541.273 498.049 544.409 497.119 548.309C496.19 552.203 494.209 556.8 491.202 561.968C485.188 572.302 475.113 584.845 461.326 598.492C433.757 625.783 391.402 657.435 337.195 684.605C282.995 711.771 226.396 727.678 182.533 735.163C160.601 738.906 141.866 740.54 128.218 740.427C121.392 740.371 115.857 739.877 111.838 738.999C109.827 738.56 108.215 738.029 107.016 737.42C105.81 736.807 105.073 736.142 104.733 735.462Z" stroke="white"/>
    <path d="M505.186 534.749C450.927 426.495 494.699 294.752 602.954 240.493C711.208 186.234 842.951 230.006 897.21 338.261C898.362 340.559 898.502 343.696 897.572 347.596C896.643 351.49 894.663 356.087 891.655 361.255C885.642 371.588 875.566 384.131 861.78 397.779C834.21 425.07 791.856 456.722 737.648 483.892C683.448 511.057 626.849 526.965 582.986 534.45C561.054 538.193 542.32 539.827 528.672 539.714C521.845 539.658 516.31 539.164 512.291 538.286C510.281 537.847 508.669 537.316 507.47 536.707C506.263 536.094 505.527 535.429 505.186 534.749Z" stroke="white"/>
    <path d="M268.103 620.639C227.383 506.6 286.82 381.143 400.859 340.423C514.898 299.703 640.355 359.14 681.075 473.18C681.94 475.601 681.698 478.731 680.301 482.49C678.907 486.242 676.383 490.565 672.771 495.33C665.548 504.857 654.024 516.085 638.683 527.958C608.006 551.701 562.123 577.979 505.019 598.369C447.923 618.757 389.811 627.678 345.364 629.784C323.14 630.837 304.346 630.186 290.812 628.418C284.043 627.533 278.609 626.371 274.726 625.012C272.784 624.332 271.248 623.61 270.132 622.859C269.009 622.105 268.358 621.355 268.103 620.639Z" stroke="white"/>
    <path d="M74.5834 689.742C33.8635 575.703 93.3005 450.246 207.34 409.526C321.379 368.806 446.836 428.243 487.556 542.282C488.42 544.704 488.178 547.834 486.782 551.593C485.388 555.345 482.864 559.668 479.251 564.432C472.028 573.96 460.505 585.187 445.164 597.061C414.486 620.804 368.603 647.082 311.499 667.472C254.403 687.859 196.291 696.78 151.845 698.887C129.62 699.94 110.826 699.289 97.2926 697.521C90.5232 696.636 85.0893 695.474 81.2063 694.115C79.2642 693.435 77.7286 692.712 76.6124 691.962C75.4896 691.208 74.8389 690.458 74.5834 689.742Z" stroke="white"/>
    <path d="M496.436 539.109C455.716 425.07 515.153 299.613 629.192 258.893C743.231 218.173 868.688 277.61 909.408 391.649C910.273 394.071 910.031 397.201 908.634 400.96C907.24 404.712 904.716 409.035 901.104 413.799C893.881 423.327 882.357 434.554 867.016 446.427C836.339 470.171 790.456 496.449 733.352 516.839C676.256 537.226 618.144 546.147 573.697 548.254C551.473 549.307 532.679 548.656 519.145 546.887C512.376 546.003 506.942 544.841 503.059 543.482C501.117 542.802 499.581 542.079 498.465 541.329C497.342 540.574 496.691 539.824 496.436 539.109Z" stroke="white"/>
    <path d="M253.243 599.71C224.386 482.108 296.329 363.379 413.931 334.522C531.533 305.666 650.262 377.608 679.119 495.211C679.731 497.708 679.171 500.797 677.398 504.393C675.628 507.983 672.675 512.026 668.595 516.396C660.436 525.136 647.826 535.128 631.353 545.372C598.41 565.857 550.082 587.31 491.194 601.76C432.314 616.207 373.595 619.145 329.165 616.7C306.95 615.477 288.32 612.91 275.038 609.768C268.395 608.196 263.108 606.485 259.384 604.737C257.522 603.862 256.068 602.986 255.034 602.126C253.994 601.26 253.424 600.448 253.243 599.71Z" stroke="white"/>
    <path d="M53.6767 648.682C24.82 531.08 96.7626 412.351 214.365 383.495C331.967 354.638 450.696 426.581 479.553 544.183C480.165 546.68 479.605 549.769 477.832 553.366C476.062 556.956 473.109 560.998 469.029 565.369C460.87 574.109 448.26 584.1 431.787 594.344C398.844 614.829 350.517 636.282 291.628 650.732C232.748 665.18 174.029 668.117 129.599 665.672C107.384 664.45 88.7545 661.882 75.4725 658.74C68.8289 657.169 63.5421 655.458 59.8183 653.709C57.9557 652.834 56.502 651.958 55.4683 651.098C54.4284 650.233 53.8578 649.42 53.6767 648.682Z" stroke="white"/>
    <path d="M488.71 541.934C459.853 424.331 531.796 305.603 649.398 276.746C767.001 247.889 885.729 319.832 914.586 437.434C915.199 439.931 914.638 443.02 912.865 446.617C911.095 450.207 908.142 454.249 904.062 458.62C895.903 467.36 883.293 477.351 866.82 487.595C833.877 508.08 785.55 529.534 726.661 543.983C667.781 558.431 609.062 561.369 564.633 558.923C542.417 557.701 523.788 555.133 510.506 551.991C503.862 550.42 498.575 548.709 494.851 546.96C492.989 546.086 491.535 545.21 490.502 544.349C489.462 543.484 488.891 542.671 488.71 541.934Z" stroke="white"/>
    </g>
    <defs>
    <radialGradient id="paint0_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(483.482 465.093) rotate(90) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint1_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(688.969 465.093) rotate(90) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint2_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(241.031 465.093) rotate(90) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint3_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(483.448 466.21) rotate(93.4659) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint4_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(688.559 478.633) rotate(93.4659) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint5_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(241.44 451.554) rotate(93.4659) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint6_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(483.304 467.66) rotate(97.9873) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint7_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(686.796 496.213) rotate(97.9873) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint8_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(243.203 433.969) rotate(97.9873) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint9_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(483.059 469.026) rotate(102.292) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint10_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(683.835 512.769) rotate(102.292) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint11_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(246.165 417.408) rotate(102.292) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint12_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(482.758 470.22) rotate(106.102) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint13_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(680.183 527.211) rotate(106.102) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint14_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(249.817 402.978) rotate(106.102) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint15_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(482.303 471.588) rotate(110.582) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint16_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(674.673 543.829) rotate(110.582) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint17_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(255.327 386.355) rotate(110.582) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint18_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(481.768 472.864) rotate(114.872) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint19_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(668.196 559.291) rotate(114.872) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint20_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(261.804 370.89) rotate(114.872) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint21_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(481.242 473.911) rotate(118.501) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint22_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(661.827 571.961) rotate(118.501) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint23_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(268.173 358.219) rotate(118.501) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint24_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(480.615 474.979) rotate(122.338) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint25_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(654.232 584.896) rotate(122.338) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint26_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(275.766 345.29) rotate(122.338) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint27_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(479.954 475.951) rotate(125.994) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint28_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(646.21 596.719) rotate(125.994) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint29_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(283.791 333.465) rotate(125.994) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint30_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(479.268 476.845) rotate(129.476) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint31_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(637.88 607.483) rotate(129.476) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint32_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(292.121 322.704) rotate(129.476) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint33_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(478.539 477.675) rotate(132.896) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint34_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(629.077 617.541) rotate(132.896) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint35_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(300.92 312.645) rotate(132.896) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint36_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(477.724 478.496) rotate(136.49) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint37_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(619.197 627.527) rotate(136.49) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint38_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(310.801 302.658) rotate(136.49) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint39_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(476.987 479.163) rotate(139.569) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint40_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(610.253 635.576) rotate(139.569) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint41_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(319.749 294.61) rotate(139.569) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint42_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(476.002 479.945) rotate(143.469) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint43_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(598.318 645.063) rotate(143.469) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint44_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(331.681 285.127) rotate(143.469) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint45_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(474.992 480.641) rotate(147.277) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint46_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(586.072 653.515) rotate(147.277) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint47_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(343.929 276.666) rotate(147.277) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint48_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(473.792 481.349) rotate(151.596) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint49_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(571.54 662.097) rotate(151.596) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint50_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(358.459 268.086) rotate(151.596) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint51_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(472.575 481.954) rotate(155.803) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint52_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(556.797 669.386) rotate(155.803) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint53_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(373.202 260.8) rotate(155.803) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint54_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(471.206 482.506) rotate(160.383) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint55_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(540.193 676.063) rotate(160.383) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint56_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(389.808 254.123) rotate(160.383) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint57_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(469.877 482.921) rotate(164.703) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint58_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(524.088 681.128) rotate(164.703) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint59_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(405.913 249.06) rotate(164.703) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint60_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(468.62 483.216) rotate(168.704) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint61_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(508.871 684.723) rotate(168.704) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint62_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(421.13 245.461) rotate(168.704) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint63_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(467.398 483.416) rotate(172.542) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint64_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(494.07 687.166) rotate(172.542) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint65_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(435.929 243.016) rotate(172.542) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint66_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(466.101 483.545) rotate(176.584) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint67_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(478.345 688.666) rotate(176.584) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint68_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(451.654 241.522) rotate(176.584) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint69_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(464.879 483.575) rotate(-179.627) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint70_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(463.541 689.058) rotate(-179.627) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint71_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(466.458 241.126) rotate(-179.627) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint72_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(463.283 483.495) rotate(-174.671) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint73_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(444.199 688.094) rotate(-174.671) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint74_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(485.801 242.092) rotate(-174.671) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint75_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(461.857 483.306) rotate(-170.211) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint76_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(426.92 685.799) rotate(-170.211) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint77_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(503.081 244.383) rotate(-170.211) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint78_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(460.432 483.002) rotate(-165.695) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint79_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(409.66 682.118) rotate(-165.695) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint80_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(520.339 248.067) rotate(-165.695) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint81_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(459.178 482.634) rotate(-161.642) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint82_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(394.462 677.66) rotate(-161.642) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint83_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(535.538 252.519) rotate(-161.642) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint84_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(457.692 482.07) rotate(-156.712) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint85_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(376.454 670.815) rotate(-156.712) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint86_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(553.546 259.371) rotate(-156.712) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint87_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(456.139 481.312) rotate(-151.354) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint88_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(357.628 661.647) rotate(-151.354) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint89_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(572.371 268.537) rotate(-151.354) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint90_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(454.686 480.431) rotate(-146.08) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint91_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(340.018 650.948) rotate(-146.08) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint92_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(589.982 279.238) rotate(-146.08) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint93_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(453.411 479.494) rotate(-141.171) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint94_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(324.571 639.572) rotate(-141.171) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint95_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(605.429 290.618) rotate(-141.171) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint96_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(452.345 478.561) rotate(-136.788) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint97_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(311.649 628.327) rotate(-136.788) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint98_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(618.351 301.856) rotate(-136.788) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint99_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(451.281 477.476) rotate(-132.07) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint100_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(298.743 615.163) rotate(-132.07) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint101_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(631.258 315.025) rotate(-132.07) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint102_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(450.269 476.253) rotate(-127.153) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint103_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(286.492 600.358) rotate(-127.153) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint104_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(643.508 329.825) rotate(-127.153) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint105_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(449.434 475.056) rotate(-122.635) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint106_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(276.389 585.873) rotate(-122.635) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint107_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(653.61 344.307) rotate(-122.635) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint108_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(448.476 473.374) rotate(-116.621) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint109_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(264.773 565.449) rotate(-116.621) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint110_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(665.226 364.736) rotate(-116.621) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint111_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(447.593 471.305) rotate(-109.65) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint112_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(254.074 540.408) rotate(-109.65) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint113_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(675.926 389.775) rotate(-109.65) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint114_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(447.05 469.494) rotate(-103.787) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint115_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(247.484 518.466) rotate(-103.787) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    <radialGradient id="paint116_angular_1_1451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(682.517 411.717) rotate(-103.787) scale(139.475 219.755)">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </radialGradient>
    </defs>
  </svg>
);