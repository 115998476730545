import React from 'react';
import className from 'classnames/bind';

// Styles
import styles from './styles.scss';
const cn = className.bind(styles);

export const TickerItem: React.FC<{
  text?: string;
}> = ({ 
  text
}) => (
  <div className={cn("ticker-container")}>
    <div className={cn("ticker-text")}>
      {text}
    </div>

    <div className={cn("ticker-icon-container")}>
      <div className={cn("ticker-icon-svg")}>
        <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.625626 2.07911C10.4654 20.8641 10.3222 28.879 0.589843 47.4851C0.498229 47.6397 0.4607 47.8205 0.483167 47.9989C0.505639 48.1772 0.586836 48.343 0.713953 48.4702C0.841071 48.5973 1.00688 48.6785 1.18524 48.701C1.36361 48.7234 1.54437 48.6859 1.69905 48.5943C20.2693 38.8261 28.5705 38.8261 47.1408 48.5943C47.2955 48.6859 47.4762 48.7234 47.6546 48.701C47.833 48.6785 47.9988 48.5973 48.1259 48.4702C48.253 48.343 48.3342 48.1772 48.3567 47.9989C48.3791 47.8205 48.3416 47.6397 48.25 47.4851C38.5176 28.879 38.4818 20.6136 48.2142 2.07911C48.3058 1.92443 48.3434 1.74367 48.3209 1.56531C48.2984 1.38694 48.2172 1.22113 48.0901 1.09401C47.963 0.966895 47.7972 0.8857 47.6188 0.863229C47.4405 0.840759 47.2597 0.878289 47.105 0.969902C28.3558 10.8454 20.3051 10.6665 1.73483 0.969902C1.58016 0.878289 1.39939 0.840759 1.22103 0.863229C1.04267 0.8857 0.876855 0.966895 0.749737 1.09401C0.622619 1.22113 0.541424 1.38694 0.51895 1.56531C0.49648 1.74367 0.534011 1.92443 0.625626 2.07911Z" fill="#030303"/>
        </svg>
      </div>
      <div className={cn("ticker-divider")} />
    </div>
  </div>
);
