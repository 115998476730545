// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__container___eHOXZ {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}
@media (min-width: 1024px) {
  .styles__container___eHOXZ {
    width: 50%;
  }
}

.styles__text-wrapper___El47a {
  width: 100%;
}
@media (min-width: 1024px) {
  .styles__text-wrapper___El47a {
    padding-right: 1rem;
  }
}
.styles__text-block___bG1FQ {
  font-size: 24px;
  color: var(--dark-01);
  font-weight: 500;
  color: black;
}
@media (min-width: 768px) {
  .styles__text-block___bG1FQ {
    font-size: 1.875rem;
  }
}
@media (min-width: 1280px) {
  .styles__text-block___bG1FQ {
    font-size: 2.08vw;
  }
}

.styles__link-block___U2mI3 {
  display: inline-block;
  width: fit-content;
}
.styles__link-href___PRG3r {
  display: inline-flex;
  align-items: center;
  border-bottom: 4px solid #000;
  white-space: nowrap;
  color: #000;
  font-weight: bold;
  line-height: 1;
  font-size: 46px;
}
.styles__link-href___PRG3r:hover {
  color: #FF6F3E;
  border-bottom-color: #FF6F3E;
}
@media (min-width: 480px) {
  .styles__link-href___PRG3r {
    font-size: 6.2vw;
  }
}
@media (min-width: 768px) {
  .styles__link-href___PRG3r {
    font-size: 4.2vw;
  }
}
@media (min-width: 976px) {
  .styles__link-href___PRG3r {
    font-size: 3.2vw;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/Footer/ExploreDecideAI/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;AACF;AACE;EANF;IAOI,UAAA;EAEF;AACF;;AAEE;EACE,WAAA;AACJ;AACI;EAHF;IAII,mBAAA;EAEJ;AACF;AACE;EACE,eAAA;EAUA,qBAAA;EACA,gBAAA;EACA,YAAA;AARJ;AAFI;EAHF;IAII,mBAAA;EAKJ;AACF;AAHI;EAPF;IAQI,iBAAA;EAMJ;AACF;;AAGE;EACE,qBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,oBAAA;EACA,mBAAA;EACA,6BAAA;EACA,mBAAA;EACA,WAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AADJ;AAGI;EACE,cAAA;EACA,4BAAA;AADN;AAII;EAfF;IAgBI,gBAAA;EADJ;AACF;AAGI;EAnBF;IAoBI,gBAAA;EAAJ;AACF;AAEI;EAvBF;IAwBI,gBAAA;EACJ;AACF","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 3.5rem;\n\n  @media (min-width: 1024px) {\n    width: 50%;\n  }\n}\n\n.text {\n  &-wrapper {\n    width: 100%;\n  \n    @media (min-width: 1024px) {\n      padding-right: 1rem;\n    }\n  }\n  \n  &-block {\n    font-size: 24px;\n  \n    @media (min-width: 768px) {\n      font-size: 1.875rem;\n    }\n  \n    @media (min-width: 1280px) {\n      font-size: 2.08vw;\n    }\n  \n    color: var(--dark-01);\n    font-weight: 500;\n    color: black;\n  }\n}\n\n.link {\n  &-block {\n    display: inline-block;\n    width: fit-content;\n  }\n\n  &-href {\n    display: inline-flex;\n    align-items: center;\n    border-bottom: 4px solid #000;\n    white-space: nowrap;\n    color: #000;\n    font-weight: bold;\n    line-height: 1;\n    font-size: 46px;\n\n    &:hover {\n      color: #FF6F3E;\n      border-bottom-color: #FF6F3E;\n    }\n\n    @media (min-width: 480px) {\n      font-size: 6.2vw;\n    }\n    \n    @media (min-width: 768px) {\n      font-size: 4.2vw;\n    }\n\n    @media (min-width: 976px) {\n      font-size: 3.2vw;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles__container___eHOXZ`,
	"text-wrapper": `styles__text-wrapper___El47a`,
	"text-block": `styles__text-block___bG1FQ`,
	"link-block": `styles__link-block___U2mI3`,
	"link-href": `styles__link-href___PRG3r`
};
export default ___CSS_LOADER_EXPORT___;
