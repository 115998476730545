// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DfinityLogo {
  height: 22px;
  width: 42px;
}
@media screen and (max-width: 992px) {
  .DfinityLogo {
    height: 15px;
    width: 32px;
  }
}

.stat-box {
  position: relative;
  padding: 1.5rem;
  box-sizing: border-box;
  border-radius: 0.25rem;
  background-color: #fff;
}
.stat-box_label {
  font-size: 14px;
  color: #1B4444;
}
.stat-box_title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  color: #1B4444;
}
.stat-box_message {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./app/common.scss","webpack://./app/components/external/provider/admin/RecentActivityStatBoxes/StatBox/styles.scss"],"names":[],"mappings":"AA0CA;EACE,YAAA;EACA,WAAA;ACzCF;AD2CE;EAJF;IAKI,YAAA;IACA,WAAA;ECxCF;AACF;;AAPA;EACE,kBAAA;EAEA,eAAA;EACA,sBAAA;EAEA,sBAAA;EAEA,sBDUM;ACHR;AALE;EACE,eAAA;EACA,cDiBO;ACVX;AAJE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EAEA,cDSO;ACJX;AAFE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;AAIJ","sourcesContent":["$secondary-text-color: #818a94;\n$horizontal-line-color: #3e4348;\n$card-background: #212121;\n$highlighted-text: #c91988;\n\n$button-background-color: #3d52fa;\n$button-background-hover-color: #5769f5;\n$button-foreground-color: #fff;\n$dark-button-border-color: #818a94;\n\n// .horizontal-line {\n//   height: 1px;\n//   width: 100%;\n//   border-bottom: 1px solid $horizontal-line-color;\n// }\n\n$primary: #3d52fa;\n$primary-hover: #5769f5;\n$secondary: #c91988;\n$success: #0fb36c;\n$white: #fff;\n$light: #c4c4c4;\n$silver: #818a94;\n$dark: #212121;\n$black: #121212;\n\n// Redesign styles\n$grey-01: #EFEFEF;\n$grey-03: #F4F4F4;\n$grey-04: #FAF9FA;\n$grey-05: #F7F7F7;\n$green-01: #1B4444;\n$green-02: #325757;\n$green-05: #8DA1A1;\n$green-03: #496969;\n$green-08: #D1DADA;\n$green-10: #E8ECEC;\n$green-11: #F4F6F6;\n$light-green-01: #66DD95;\n$light-green-03: #94E7B5;\n$light-green-09: #f0fcf4;\n\n.DfinityLogo {\n  height: 22px;\n  width: 42px;\n\n  @media screen and (max-width: 992px) {\n    height: 15px;\n    width: 32px;\n  }\n}\n","@import \"../../../../../../common.scss\";\n\n.stat-box {\n  position: relative;\n\n  padding: 1.5rem;\n  box-sizing: border-box;\n\n  border-radius: 0.25rem;\n\n  background-color: $white;\n\n  &_label {\n    font-size: 14px;\n    color: $green-01;\n  }\n\n  &_title {\n    font-size: 3rem;\n    font-weight: 700;\n    line-height: 1;\n\n    color: $green-01;\n  }\n\n  &_message {\n    position: absolute;\n    top: 1.5rem;\n    right: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
