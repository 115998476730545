// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__gpt___xYf80 {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 284px;
  height: 284px;
  transition: transform 2s ease-in-out;
  transform-origin: center;
}
.styles__gpt___xYf80 svg {
  width: 100%;
  height: 100%;
}
@media (min-width: 1024px) {
  .styles__gpt___xYf80 {
    margin-bottom: 1.38vw;
    width: 12.72vw;
    height: 12.72vw;
  }
}

.styles__animate____wlOd {
  transform: rotate(360deg);
}`, "",{"version":3,"sources":["webpack://./app/components/external/MainLanding/ProjectsSection/SpotlightPreview/Gpt2Decor/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EAEA,oCAAA;EACA,wBAAA;AADF;AAGE;EACE,WAAA;EACA,YAAA;AADJ;AAIE;EAjBF;IAkBI,qBAAA;IAEA,cAAA;IACA,eAAA;EAFF;AACF;;AAKA;EACE,yBAAA;AAFF","sourcesContent":[".gpt {\n  margin-bottom: 20px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 284px;\n  height: 284px;\n  \n  transition: transform 2s ease-in-out;\n  transform-origin: center;\n\n  svg {\n    width: 100%;\n    height: 100%;\n  }\n\n  @media (min-width: 1024px) {\n    margin-bottom: 1.38vw;\n\n    width: 12.72vw;\n    height: 12.72vw;\n  }\n}\n\n.animate {\n  transform: rotate(360deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gpt": `styles__gpt___xYf80`,
	"animate": `styles__animate____wlOd`
};
export default ___CSS_LOADER_EXPORT___;
